import * as React from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import UseDimensions from '../../services/UseDimensions';

import Text from '../Modules/Text';
import QuantityInput from '../Modules/QuantityInput';
import RemoveButton from '../Modules/RemoveButton';

import Colors from '../../styles/Colors';
import Breakpoint from '../../styles/Breakpoints';
import useMoneyFormat from '../../services/useMoneyFormat';

export default function CartItem(props) {
  const size = UseDimensions();
  const dispatch = useDispatch();
  const timeoutRef = React.useRef();
  const formatMoney = useMoneyFormat();

  const removeLine = () => {
    axios({
      method: 'delete',
      url: '/cart/remove',
      data: {
        'cart-line-token': props.id,
      },
    })
      .then(() => {
        dispatch({
          type: 'DELETE_LINE',
          id: props.id,
        });
      });
  };

  /*
  * direction
  * lower = 0
  * higher = 1
  */
  const changeQuantity = (direction) => {
    const newQuantity = direction
      ? (parseInt(props.quantity, 10) + 1)
      : (parseInt(props.quantity, 10) - 1);

    dispatch({
      type: 'UPDATE_LINE',
      id: props.id,
      payload: {
        quantity: newQuantity,
        amount_excl_vat: (props.amountExclVat / props.quantity) * newQuantity,
        amount_incl_vat: (props.amount / props.quantity) * newQuantity,
      },
    });

    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;

    timeoutRef.current = setTimeout(() => {
      axios({
        method: 'post',
        url: '/cart/quantity',
        data: {
          'cart-line-token': props.id,
          quantity: newQuantity,
        },
      })
        .then(() => {});
    }, 500);
  };

  return (
    <div
      style={{
        height: 'auto',
        width: '100%',
        borderBottom: `solid 1px ${Colors.MEDIUM_GREY}`,
        paddingBottom: 10,
        paddingTop: 10,
        display: 'flex',
        flexDirection: size.width < 620 ? 'column' : 'row',
        justifyContent: size.width < Breakpoint.sm ? 'center' : 'space-between',
      }}
    >
      <div
        style={{
          overflow: 'hidden',
          minHeight: 250,
          minWidth: 250,
          maxHeight: 250,
          maxWidth: 250,
          borderRadius: 7,
          backgroundImage: `url("${process.env.REACT_APP_CMS_MEDIA_URL + props.image}")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <div
        style={{
          width: '100%',
          marginLeft: size.width < 620 ? 0 : 30,
          height: 250,
        }}
      >
        <div
          style={{
            height: 50,
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Text
            fontWeight={500}
            fontSize="1.25em"
            textAlign="left"
          >
            {props.name}
          </Text>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: 200,
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: size.width < 1200 ? 'column' : 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
            >
              {
                size.width < Breakpoint.sm && (
                  <div
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                  >
                    <Text
                      fontWeight={500}
                      fontSize="1.5em"
                      textAlign="left"
                    >
                      {formatMoney(props.amount)}
                    </Text>
                  </div>
                )
              }
              {
                  props.product_options && props.product_options.map(({ key, value }) => {
                    if (!key) return null;

                    return (
                      <>
                        <div
                          style={{
                            marginBottom: 3,
                          }}
                        >
                          <Text
                            textAlign="left"
                          >
                            {key}
                            :
                          </Text>
                        </div>
                        <Text
                          textAlign="left"
                          fontWeight={500}
                        >
                          {value}
                        </Text>
                      </>
                    );
                  })
              }
            </div>
            <div
              style={{
                marginRight: size.width < 1200 ? 0 : 50,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: size.width < 1200 ? 'space-between' : 'center',
                alignItems: 'center',
              }}
            >
              <QuantityInput
                amount={props.quantity}
                onClickMinus={() => changeQuantity(0)}
                onClickPlus={() => changeQuantity(1)}
              />
              {
                size.width < Breakpoint.sm && (
                  <div
                    style={{
                      marginLeft: 20,
                    }}
                  >
                    <RemoveButton
                      onClick={removeLine}
                    />
                  </div>
                )
              }
            </div>
          </div>
          {
            size.width >= Breakpoint.sm && (
              <div
                style={{
                  width: 100,
                  minWidth: 100,
                  maxWidth: 150,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text
                  fontWeight={500}
                  fontSize="1.25em"
                  textAlign="center"
                >
                  {formatMoney(props.amount)}
                </Text>
              </div>
            )
          }
          {
            size.width >= Breakpoint.sm && (
              <div
                style={{
                  marginLeft: 50,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <RemoveButton
                  onClick={removeLine}
                />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
