const Config = {

  /* ===
    Language Settings
  === */
  Language: 'nl',
  FallbackLanguage: 'nl',

};

export default Config;
