import * as React from 'react';
import { Link } from 'react-router-dom';

import Colors from '../../../styles/Colors';
import Breakpoint from '../../../styles/Breakpoints';

import UseDimensions from '../../../services/UseDimensions';

import Text from '../../Modules/Text';

export default function CategoryBlock(props) {
  const size = UseDimensions();

  const [height, setHeight] = React.useState(0);
  const [hover, setHover] = React.useState(false);
  const wrapperRef = React.useRef(0);

  React.useEffect(() => {
    setHeight(wrapperRef.current.clientWidth);
  }, [size]);

  return (
    <Link to={`/category/${props.slug ?? props.id}`}>
      <div
        ref={wrapperRef}
        className="Hover"
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        style={{
          width: '100%',
          backgroundColor: Colors.LIGHT_GREY,
          position: 'relative',
          minHeight: 250,
          marginBottom: size.width < Breakpoint.xs ? 50 : 0,
          borderRadius: 10,
          overflow: 'hidden',
          margin: 0,
          padding: 0,
          height,
          border: `solid 2px ${hover ? Colors.MEDIUM_GREY : 'transparent'}`,
        }}
      >
        <div
          style={{
            backgroundColor: `${Colors.BLACK}30`,
            height: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            fontWeight={700}
            color={Colors.WHITE}
            fontSize="2em"
          >
            {props.title}
          </Text>
        </div>
        <div
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            backgroundImage: `url(${props.src})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            zIndex: 0,
            transform: `scale(${hover ? 1.1 : 1})`,
            transition: 'all .2s linear',
          }}
        />
      </div>
    </Link>
  );
}
