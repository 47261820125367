import * as React from 'react';
import {
  Menu,
  ChevronUp,
  ChevronDown,
} from 'react-feather';

// import Colors from '../../../styles/Colors';

export default function MenuIcon(props) {
  return (
    <button
      type="button"
      onClick={props.onClick}
      aria-label="Menu"
      className="Hover"
      style={{
        height: 60,
        width: 60,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: 'solid 0px transparent',
        paddingTop: 3,
      }}
    >
      <Menu />
      {
        props.active ? (
          <ChevronUp />
        ) : (
          <ChevronDown />
        )
      }
    </button>
  );
}
