import * as React from 'react';
import axios from 'axios';

import { useRef } from 'react';
import UseDimensions from '../services/UseDimensions';

import Colors from '../styles/Colors';
import Breakpoint from '../styles/Breakpoints';

import MainContainer from '../components/Containers/MainContainer';
import Text from '../components/Modules/Text';
import PageLoader from '../components/Modules/PageLoader';

export default function Colorsheet() {
  const size = UseDimensions();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [rows, setRows] = React.useState([]);
  const [width, setWidth] = React.useState('100%');
  const [colors, setColors] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const officialWrapperRef = useRef(null);
  const wrapperRef = React.useCallback((node) => {
    if (node !== null) {
      officialWrapperRef.current = node;
      setWidth(node.clientWidth);
    }
  }, []);

  React.useEffect(() => {
    const handleUpdate = ({ data }) => {
      if (data.event === 'cockpit:content.preview' && data.context?.model === 'colorsheet') {
        setColors(data.data);
      }
    };

    window.addEventListener('message', handleUpdate);

    return () => window.removeEventListener('message', handleUpdate);
  }, []);

  React.useEffect(() => {
    axios({
      method: 'get',
      url: '/content/item/colorsheet',
    })
      .then((response) => {
        setColors(response.data);
        setLoading(false);
      })
      .catch(() => {});
  }, []);

  const responsive = () => {
    if (size.width > Breakpoint.xl) return 5;
    if (size.width > Breakpoint.sm && size.width <= Breakpoint.xl) return 4;
    if (size.width > Breakpoint.xs && size.width <= Breakpoint.sm) return 3;
    if (size.width > Breakpoint.fd && size.width <= Breakpoint.xs) return 2;
    if (size.width <= Breakpoint.fd) return 1;
    return 2;
  };

  React.useEffect(() => {
    if (colors?.colors) {
      const array = [];

      for (let i = 0; i < colors.colors.length; i += responsive()) {
        array.push(colors.colors.slice(i, i + responsive()));
      }

      setRows(array);
    }
  }, [size, colors?.colors]);

  const getDimensions = () => {
    if (officialWrapperRef.current?.clientWidth) {
      setWidth(officialWrapperRef.current.clientWidth);
    }
  };

  React.useEffect(() => {
    getDimensions();
  }, [officialWrapperRef.current]);

  React.useEffect(() => {
    window.addEventListener('resize', getDimensions);

    return () => {
      window.addEventListener('resize', getDimensions);
    };
  }, []);

  const itemWidth = (width - (15 * (responsive() - 1))) / responsive();

  if (loading) {
    return (
      <PageLoader
        visible={loading}
      />
    );
  }

  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
        paddingTop: 50,
      }}
    >
      <MainContainer>
        <div
          style={{
            paddingBottom: 50,
          }}
        >
          <Text
            textAlign="center"
            fontSize="2em"
            fontWeight={500}
          >
            {colors.title}
          </Text>
        </div>
        <div
          ref={wrapperRef}
          style={{
            width: '100%',
          }}
        >
          {
            rows.map((columns, index) => (
              <div
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: columns.length < responsive() ? 'flex-start' : 'space-between',
                  paddingTop: index === 0 ? 15 : 0,
                  marginBottom: 15,
                }}
              >
                {
                  columns.map((item, i) => (
                    <div
                      /* eslint-disable-next-line react/no-array-index-key,no-underscore-dangle */
                      key={`${item._id}-${i}`}
                      style={{
                        height: 400,
                        width: itemWidth || 'auto',
                        backgroundColor: item.content || Colors.LIGHT_GREY,
                        marginBottom: 10,
                        marginRight: columns.length < responsive() ? 15 : 'initial',
                        backgroundImage: `url(${process.env.REACT_APP_CMS_MEDIA_URL}${item.image.path})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        overflow: 'hidden',
                        borderRadius: 5,
                        position: 'relative',
                      }}
                    >
                      <div
                        style={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: (item.hex_value !== '' && item.hex_value !== null) ? 'space-between' : 'flex-end',
                          position: 'relative',
                        }}
                      >
                        {
                          (item.hex_value !== '' && item.hex_value !== null) && (
                            <div
                              style={{
                                width: '100%',
                                height: 'auto',
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <div
                                style={{
                                  paddingTop: 10,
                                  paddingBottom: 5,
                                  background: 'rgba(256,256,256,0.8)',
                                  backdropFilter: 'blur(20px)',
                                  width: 'auto',
                                  height: 'auto',
                                  borderBottomRightRadius: 15,
                                }}
                              >
                                <div
                                  style={{
                                    paddingLeft: 10,
                                    paddingRight: 20,
                                  }}
                                >
                                  <Text
                                    textAlign="left"
                                    fontWeight={500}
                                    color="#000000"
                                  >
                                    {item.hex_value}
                                  </Text>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        <div
                          style={{
                            height: 'auto',
                            width: '100%',
                            background: 'rgba(256,256,256,0.8)',
                            backdropFilter: 'blur(20px)',
                            paddingBottom: 10,
                            paddingTop: 10,
                          }}
                        >
                          <div
                            style={{
                              paddingLeft: 10,
                              paddingRight: 10,
                            }}
                          >
                            <Text
                              textAlign="left"
                              fontWeight={500}
                              fontSize="1.25em"
                              color="#000000"
                            >
                              {item.name}
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            ))
          }
        </div>
      </MainContainer>
    </div>
  );
}
