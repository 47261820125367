import * as React from 'react';
import { ShoppingBag, Loader } from 'react-feather';
import { motion } from 'framer-motion';

import Colors from '../../styles/Colors';

export default function Button(props) {
  const [hover, setHover] = React.useState(false);

  return (
    <button
      type="button"
      className={props.loading ? 'Loading' : props.disabled ? 'Disabled' : 'Hover'}
      onClick={props.disabled || props.loading ? undefined : props.onClick}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        width: '100%',
        fontWeight: props.fontWeight ?? 300,
        fontFamily: 'Hurme Geometric Sans',
        fontSize: props.fontSize ?? '1.1em',
        textTransform: props.textTransform ?? 'none',
        color: (props.disabled) ? Colors.GREY : (props.color ?? Colors.BLACK),
        backgroundColor: (props.disabled) || hover
          ? props.backgroundColor ?? Colors.LIGHT_GREY
          : props.backgroundColor ?? Colors.WHITE,
        border: props.border ?? `solid 1px ${(props.disabled) ? Colors.LIGHT_GREY : Colors.MEDIUM_GREY}`,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 5,
        height: props.height ?? 60,
        transition: 'all .2s linear',
      }}
    >
      {
        props.loading
          ? (
            <motion.div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'columns',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              initial={{
                rotate: 0,
              }}
              animate={{
                rotate: 360,
              }}
              transition={{
                repeat: Infinity,
                duration: 2,
                ease: 'linear',
              }}
            >
              <Loader
                color={Colors.GREY}
                height={30}
                width={30}
              />
            </motion.div>
          )
          : props.added
            ? 'Toegevoegd'
            : props.price
              ? (
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    style={{
                      marginRight: 7,
                      marginTop: 3,
                    }}
                  >
                    <ShoppingBag
                      height={20}
                      width={20}
                    />
                  </span>
                  {props.price}
                </span>
              )
              : props.text
      }
    </button>
  );
}
