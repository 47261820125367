import * as React from 'react';

import Colors from '../../../styles/Colors';
import Breakpoint from '../../../styles/Breakpoints';

import UseDimensions from '../../../services/UseDimensions';

import Container from '../../Containers/Container';

import Text from '../../Modules/Text';
import Button from '../../Modules/Button';

export default function TextWithImage(props) {
  const size = UseDimensions();

  return (
    <Container
      breakpoint="sm"
      invert={props.invert}
    >
      <div
        style={{
          minHeight: 200,
          height: size.width < Breakpoint.sm ? 'auto' : '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: size.width < Breakpoint.sm && props.invert ? 100 : 25,
          marginTop: size.width < Breakpoint.sm && props.invert ? 100 : 0,
        }}
      >
        <div
          style={{
            paddingLeft: 40,
            paddingRight: 40,
          }}
        >
          <div
            style={{
              marginBottom: 10,
            }}
          >
            <Text
              fontWeight={500}
              fontSize="2em"
              textAlign={props.align ?? 'left'}
            >
              {props.title}
            </Text>
          </div>
          <Text
            textAlign={props.align ?? 'left'}
          >
            {props.text}
          </Text>
          {
            props.button && (
              <div
                style={{
                  width: '60%',
                  marginLeft: '20%',
                  marginRight: '20%',
                  marginTop: 20,
                }}
              >
                {
                  props.button.map((e) => (
                    <Button
                      key={e.id}
                      text={e.text}
                      onClick={e.onClick}
                    />
                  ))
                }
              </div>
            )
          }
        </div>
      </div>
      <div
        style={{
          height: size.width < Breakpoint.sm ? 450 : 600,
          width: '100%',
          backgroundColor: Colors.LIGHT_GREY,
          borderRadius: 10,
          backgroundImage: `url(${props.src})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      />
    </Container>
  );
}
