import * as React from 'react';
import { motion } from 'framer-motion';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UseDimensions from '../../../services/UseDimensions';

import Breakpoint from '../../../styles/Breakpoints';

import MainContainer from '../../Containers/MainContainer';

import Text from '../../Modules/Text';
import Slider from '../../Modules/Slider';

import ProductItem from '../ProductItem';
import getPrimaryImage from '../../../services/getPrimaryImage';

export default function Row(props) {
  const size = UseDimensions();

  const responsive = () => {
    if (size.width >= Breakpoint.lg && size.width < 1420) return 3;
    if (size.width >= Breakpoint.sm && size.width < Breakpoint.lg) return 2;
    if (size.width < Breakpoint.sm) return 1;
    return 4;
  };

  return (
    <>
      <div
        style={{
          marginBottom: 10,
        }}
      >
        <MainContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text
              textAlign="center"
              fontSize="2em"
              fontWeight={500}
            >
              {props.title}
            </Text>
          </div>
        </MainContainer>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Slider
          items={responsive()}
          type="row"
        >
          {
            props.data.map((e, index) => {
              const collection = useSelector(
                (state) => state.collections.find(
                  // eslint-disable-next-line no-underscore-dangle
                  (data) => data._id === e.category._id,
                ),
              );

              // eslint-disable-next-line react/no-array-index-key
              if (!collection) return <div key={index} />;

              return (
                <motion.div
                  /* eslint-disable-next-line no-underscore-dangle,react/no-array-index-key */
                  key={e._id + index}
                >
                  <Link
                    /* eslint-disable-next-line no-underscore-dangle */
                    to={`/product/${e.slug ?? e._id}`}
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <ProductItem
                      product={e.name}
                      category="Honden"
                      price={e.price}
                      image={getPrimaryImage(e.images).href}
                    />
                  </Link>
                </motion.div>
              );
            })
          }
        </Slider>
      </div>
    </>
  );
}
