import * as React from 'react';
import { Check } from 'react-feather';

import Colors from '../../../styles/Colors';
import Breakpoint from '../../../styles/Breakpoints';

import UseDimensions from '../../../services/UseDimensions';

import Text from './index';

export default function Checkbox(props) {
  const size = UseDimensions();

  return (
    <div
      onClick={() => props.onChange(!props.checked)}
      role="button"
      aria-label={`Checkbox for ${props.title}`}
      tabIndex={0}
      aria-hidden="true"
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: 15,
        marginTop: size.width < Breakpoint.sm ? 20 : 15,
        marginBottom: 15,
      }}
    >
      <div
        style={{
          height: 15,
          width: 15,
          minWidth: 15,
          border: props.checked ? `solid 1px ${Colors.BLACK}` : `solid 1px ${Colors.MEDIUM_GREY}`,
          marginRight: 7,
          marginTop: 1.5,
          borderRadius: 3,
          backgroundColor: 'transparent',
          position: 'relative',
        }}
      >
        {
        props.checked && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
            }}
          >
            <Check
              color={Colors.BLACK}
              height={15}
              width={15}
            />
          </div>
        )
      }
      </div>
      <Text
        textAlign="left"
      >
        {props.title}
      </Text>
    </div>
  );
}
