import * as React from 'react';
import {
  AnimatePresence,
  motion,
} from 'framer-motion';
import { useSelector } from 'react-redux';

import Breakpoint from '../../../styles/Breakpoints';
import Colors from '../../../styles/Colors';

import UseDimensions from '../../../services/UseDimensions';

import SideMenuItem from './SideMenuItem';

export default function SideMenu(props) {
  const size = UseDimensions();
  const collections = useSelector((state) => state.collections);

  const menuItems = [
    {
      id: 0,
      title: 'Over ons',
      href: '/about',
      icon: 'fa-paw',
    },
    {
      id: 1,
      title: 'Kleurenkaart',
      href: '/kleurenkaart',
      icon: 'fa-palette',
    },
  ];

  return (
    <AnimatePresence>
      {
        props.visible && (
          <motion.div
            key="SideMenu"
            style={{
              width: size.width < Breakpoint.xs ? '95%' : 300,
              height: '100vh',
              position: 'fixed',
              right: 0,
              top: 0,
              paddingTop: size.width < Breakpoint.sm ? 100 : 141,
              backgroundColor: Colors.WHITE,
              zIndex: 98,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              borderLeft: `solid 1px ${Colors.MEDIUM_GREY}`,
            }}
            initial={{
              right: size.width < Breakpoint.xs ? '-95%' : -300,
            }}
            animate={{
              right: 0,
            }}
            exit={{
              right: size.width < Breakpoint.xs ? '-95%' : -300,
            }}
            transition={{
              duration: 0.4,
            }}
          >
            <div
              style={{
                marginTop: 15,
                padding: 15,
                width: 'calc(100% - 30px)',
              }}
            >
              {
                size.width < Breakpoint.sm && (
                  <>
                    {
                      collections.map(({ _id, name, icon }, index) => (
                        <div
                          key={_id}
                          style={{
                            marginTop: index === 0 ? 0 : 5,
                          }}
                        >
                          <SideMenuItem
                            title={name}
                            href={`/category/${_id}`}
                            icon={icon}
                            onClick={props.onClick}
                          />
                        </div>
                      ))
                    }
                    <div
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        width: '100%',
                        height: 2,
                        backgroundColor: Colors.LIGHT_GREY,
                      }}
                    />
                  </>
                )
              }
              {
                menuItems.map((e) => (
                  <div
                    key={e.id}
                    style={{
                      marginTop: e.id === 0 ? 0 : 5,
                    }}
                  >
                    <SideMenuItem
                      title={e.title}
                      href={e.href}
                      icon={e.icon}
                      onClick={props.onClick}
                    />
                  </div>
                ))
              }
            </div>
          </motion.div>
        )
      }
    </AnimatePresence>
  );
}
