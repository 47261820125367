import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Colors from '../../../styles/Colors';

import Text from '../../Modules/Text';

export default function ProductItem(props) {
  const history = useHistory();

  const [loaded, setLoaded] = React.useState(false);
  const [hover, setHover] = React.useState(false);

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <button
        type="button"
        className="Hover"
        onClick={() => history.push(`/${props.category}/${props.product}`)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          width: '100%',
          height: 450,
          minWidth: 350,
          backgroundColor: Colors.WHITE,
          opacity: loaded ? 1 : 0,
          transition: 'opacity .5s ease-in',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
          border: 'solid 0px transparent',
        }}
      >
        <div
          style={{
            width: '95%',
            marginLeft: '2.5%',
            marginRight: '2.5%',
            backgroundColor: Colors.WHITE,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              overflow: 'hidden',
              height: '100%',
              width: '100%',
              position: 'relative',
              borderRadius: 5,
            }}
          >
            <div
              style={{
                height: '100%',
                width: '100%',
                backgroundColor: Colors.LIGHT_GREY,
                backgroundImage: `url(${props.image})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                transform: `scale(${hover ? 1.1 : 1})`,
                transition: 'all .2s linear',
              }}
            />
          </div>
          <div
            style={{
              height: 'auto',
              padding: 10,
            }}
          >
            <Text
              fontSize="1.5em"
              textAlign="left"
              fontWeight={500}
            >
              {props.product}
            </Text>
          </div>
        </div>
      </button>
    </>
  );
}
