import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Colors from '../../../styles/Colors';

export default function Navigation(props) {
  const [hover, setHover] = React.useState(false);

  return (
    <RouterLink
      to={props.href}
      className="Hover"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={props.onClick}
      style={{
        width: '100%',
        textAlign: props.textAlign ?? 'center',
        paddingTop: 2,
        fontWeight: props.fontWeight ?? 300,
        fontFamily: 'Hurme Geometric Sans',
        fontSize: props.fontSize ?? '1.1em',
        textTransform: props.textTransform ?? 'none',
        color: props.color ?? Colors.BLACK,
        textDecoration: hover ? 'underline' : 'none',
      }}
    >
      {props.title}
    </RouterLink>
  );
}
