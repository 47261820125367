import * as React from 'react';
import { useSelector } from 'react-redux';
import { ShoppingBag } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';

import Colors from '../../../styles/Colors';

export default function ShoppingCart() {
  const cartSize = useSelector((state) => state.cart?.lines?.length);

  return (
    <RouterLink
      to="/cart"
      className="Hover"
      style={{
        textDecoration: 'none',
      }}
    >
      <div
        style={{
          height: 60,
          width: 50,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ShoppingBag
            color={Colors.BLACK}
            height={22}
            width={22}
          />
          <p
            style={{
              marginTop: 5,
              marginLeft: 3,
              fontSize: '1.1em',
              fontFamily: 'Hurme Geometric Sans',
              fontWeight: 500,
              color: Colors.BLACK,
            }}
          >
            {cartSize ?? 0}
          </p>
        </div>
      </div>
    </RouterLink>
  );
}
