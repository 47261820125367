import * as React from 'react';
import {
  ChevronLeft,
  ChevronRight,
} from 'react-feather';

import Colors from '../../../styles/Colors';

export default function Arrow(props) {
  const [hover, setHover] = React.useState(false);
  return (
    props.visible && (
      <button
        type="button"
        className="Hover"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={!props.disabled ? props.onClick : null}
        style={{
          position: 'absolute',
          height: 60,
          width: 60,
          top: props.type === 'row' ? '35%' : '40%',
          left: props.left ? 0 : 'initial',
          right: props.right ? 0 : 'initial',
          border: `solid 2px ${Colors.LIGHT_GREY}`,
          background: hover ? Colors.LIGHT_GREY : Colors.WHITE,
          borderTopRightRadius: props.left ? 10 : 0,
          borderBottomRightRadius: props.left ? 10 : 0,
          borderTopLeftRadius: props.right ? 10 : 0,
          borderBottomLeftRadius: props.right ? 10 : 0,
        }}
      >
        {
          props.left && (
            <ChevronLeft
              color={Colors.GREY}
              height={40}
              width={40}
            />
          )
        }
        {
          props.right && (
            <ChevronRight
              color={Colors.GREY}
              height={40}
              width={40}
            />
          )
        }
      </button>
    )
  );
}
