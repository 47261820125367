import {
  SET_COLLECTIONS,
} from '../actionTypes';

const initialState = [];

// eslint-disable-next-line default-param-last
const update = (state = initialState, action) => {
  switch (action.type) {
    case SET_COLLECTIONS:
      return action.payload;

    default: return state;
  }
};

export default update;
