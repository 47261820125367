import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Colors from '../../../styles/Colors';

export default function NavItem(props) {
  const [hover, setHover] = React.useState(false);
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    setActive(window.location.pathname === props.href);
  }, [props.href, window.location.pathname]);

  return (
    <>
      <RouterLink
        to={props.href}
        className="Hover"
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        style={{
          textDecorationLine: 'none',
          color: props.color ?? Colors.BLACK,
          width: 'auto',
          paddingLeft: 20,
          paddingRight: 20,
          height: 35,
        }}
      >
        <p
          style={{
            width: '100%',
            textAlign: 'center',
            paddingTop: 2,
            textTransform: 'capitalize',
            fontWeight: 300,
            fontFamily: 'Hurme Geometric Sans',
            fontSize: '1.1em',
            paddingBottom: 7,
            borderBottom: `solid 2px ${hover || active ? props.color ?? Colors.BLACK : 'transparent'}`,
          }}
        >
          {props.name}
        </p>
      </RouterLink>
    </>
  );
}
