import * as React from 'react';

import Link from './Text/Link';

export default function Breadcrumbs(props) {
  return (
    <p>
      {
          props.pages.map((e, key) => (
            <React.Fragment
              key={e.id}
            >
              <span
                style={{
                  paddingRight: 7,
                }}
              >
                <Link
                  href={e.href}
                  title={e.title}
                  textTransform="capitalize"
                  fontWeight={300}
                  fontSize="1em"
                />
                {
                  key !== props.pages.length - 1 && (
                    <span
                      style={{
                        fontWeight: 100,
                      }}
                    >
                      {' '}
                      /
                    </span>
                  )
                }

              </span>
            </React.Fragment>
          ))
        }
    </p>
  );
}
