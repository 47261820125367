import * as React from 'react';
import ReactGA from 'react-ga4';
import Cookie from 'cookie-universal';

import Colors from '../../styles/Colors';

import MainContainer from '../Containers/MainContainer';

import Text from './Text';
import Button from './Button';

export default function CookiePopup() {
  const cookies = Cookie();

  const [open, setOpen] = React.useState(false);

  const setYearFromDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1),
  );

  React.useEffect(() => {
    if (cookies.get('cookies') === undefined) setOpen(true);
  }, []);

  const acceptCookies = () => {
    setOpen(false);
    cookies.set('cookies', 'true', { expires: setYearFromDate });
    ReactGA.initialize('G-VM975BXKWV');
  };

  if (!open) return null;

  return (
    <div
      style={{
        height: 'auto',
        width: '100%',
        backgroundColor: Colors.BLACK,
        position: 'fixed',
        bottom: 0,
        zIndex: 2147483646,
      }}
    >
      <MainContainer>
        <div
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Text
            textAlign="left"
            color={Colors.WHITE}
            fontWeight={500}
          >
            Deze website gebruikt cookies om functies voor
            sociale media te bieden en om ons verkeer te analyseren.
          </Text>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 10,
            }}
          >
            <div
              style={{
                marginRight: 20,
              }}
            >
              <Button
                text="Accepteren"
                color={Colors.WHITE}
                backgroundColor={Colors.BLACK}
                height="auto"
                border="solid 0px transparent"
                textAlign="left"
                onClick={acceptCookies}
              />
            </div>
            <div>
              <Button
                text="Weigeren"
                color={Colors.WHITE}
                backgroundColor={Colors.BLACK}
                height="auto"
                border="solid 0px transparent"
                textAlign="left"
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
        </div>
      </MainContainer>
    </div>
  );
}
