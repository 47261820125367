const Colors = {
  // Basic Colors
  RED: '#EA4D4D',
  WHITE: '#FFFFFF',
  LIGHT_GREY: '#F5F5F5',
  MEDIUM_GREY: '#D4D2D3',
  GREY: '#949394',
  BLACK: '#202020',
};

export default Colors;
