import * as React from 'react';

import Colors from '../../styles/Colors';

import Text from './Text';

export default function TextInput(props) {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        <Text
          textAlign="left"
          fontSize="1em"
          color={props.error ? Colors.RED : Colors.BLACK}
        >
          {`${props.label}:`}
          <span
            style={{
              marginLeft: 10,
              fontSize: '0.8em',
            }}
          >
            {
              !props.required ? 'Optioneel' : ''
            }
          </span>
        </Text>
      </div>
      <div
        style={{
          width: '100%',
          maxWidth: '100%',
          position: 'relative',
          display: 'flex',
        }}
      >
        <input
          name={props.name}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          max={props.max}
          required={props.required}
          autoComplete={props.autoComplete}
          style={{
            height: 40,
            width: '100%',
            backgroundColor: Colors.WHITE,
            borderRadius: 5,
            fontSize: '1em',
            border: `solid 1px ${props.error ? Colors.RED : Colors.MEDIUM_GREY}`,
            paddingLeft: 5,
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 5,
            margin: 0,
            display: 'block',
            color: Colors.BLACK,
          }}
        />
        {
          props.max && (
            <div
              style={{
                height: 39,
                width: 39,
                backgroundColor: Colors.WHITE,
                position: 'absolute',
                top: 2,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text>{props.value ? props.max - props.value.length : props.max}</Text>
            </div>
          )
        }
      </div>
      {
        props.error && (
          <div
            style={{
              marginTop: 5,
            }}
          >
            <Text
              textAlign="left"
              fontSize="1em"
              color={Colors.RED}
            >
              {`- ${props.error}`}
            </Text>
          </div>
        )
      }
    </div>
  );
}
