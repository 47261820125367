import * as React from 'react';
import {
  ChevronDown,
  ChevronUp,
} from 'react-feather';

import Colors from '../../../styles/Colors';

import Text from '../Text';

import Option from './Option';

export default function Dropdown(props) {
  const ref = React.useRef();

  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    function outsideClickHandler(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setActive(false);
      }
    }

    document.addEventListener('mousedown', outsideClickHandler);
    return () => document.removeEventListener('mousedown', outsideClickHandler);
  }, [ref]);

  return (
    <div
      ref={ref}
      style={{
        width: '100%',
        position: 'relative',
      }}
    >
      <div>
        <Text
          textAlign="left"
          fontSize="1em"
        >
          {props.label}
          :
        </Text>
      </div>
      <button
        className="Hover"
        type="button"
        onClick={() => {
          setActive(!active);
        }}
        style={{
          height: 40,
          width: '100%',
          backgroundColor: Colors.WHITE,
          border: `solid 1px ${Colors.MEDIUM_GREY}`,
          fontSize: '1em',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            marginTop: 2.5,
          }}
        >
          <Text
            textAlign="left"
          >
            {
              !props.selected ? 'Selecteer' : props.selected
            }
          </Text>
        </div>
        <div
          style={{
            height: 38,
            marginTop: 1,
            width: 20,
            backgroundColor: Colors.WHITE,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
        >
          { active ? (<ChevronUp />) : (<ChevronDown />) }
        </div>
      </button>
      {
        active && (
          <div
            onMouseEnter={() => {
              setActive(true);
            }}
            onMouseLeave={() => {
              setActive(false);
            }}
            style={{
              height: 'auto',
              width: '100%',
              position: 'absolute',
              paddingTop: 5,
              zIndex: 1,
              backgroundColor: 'transparent',
            }}
          >
            <div
              style={{
                width: '100%',
                height: 'auto',
                backgroundColor: Colors.WHITE,
                display: 'flex',
                flexDirection: 'column',
                border: `solid 1px ${Colors.MEDIUM_GREY}`,
                borderRadius: 5,
                overflow: 'hidden',
              }}
            >
              {
                props.options.map((e, i) => (
                  <Option
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={i}
                    data={e}
                    onClick={() => {
                      e.onClick();
                      setActive(!active);
                    }}
                  />
                ))
              }
            </div>
          </div>
        )
      }
    </div>
  );
}
