import * as React from 'react';
import { Link } from 'react-router-dom';

import Colors from '../../../styles/Colors';
import Breakpoint from '../../../styles/Breakpoints';

import UseDimensions from '../../../services/UseDimensions';
import GetScrollHeight from '../../../services/GetScrollHeight';
import GetScrollDirection from '../../../services/GetScrollDirection';

import MainContainer from '../../Containers/MainContainer';

import MenuIcon from './MenuIcon';
import ShoppingCart from './ShoppingCart';
import Navigation from './Navigation';
import Topbar from './Topbar';
import SideMenu from './SideMenu';

export default function Header(props) {
  const size = UseDimensions();
  const scrollHeight = GetScrollHeight();
  const scrollDirection = GetScrollDirection();

  const [sideMenu, setSideMenu] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    document.querySelector('html').style.overflowY = sideMenu ? 'hidden' : 'auto';
  }, [sideMenu]);

  return (
    <>
      <Topbar topbar={props.topbar} />
      <header
        style={{
          height: size.width < Breakpoint.md ? 70 : 105,
          width: '100%',
          backgroundColor: Colors.WHITE,
          top: (((scrollHeight > 36) && scrollDirection === 'down') || !props.topbar) ? 0 : 35,
          position: 'fixed',
          transition: 'top .2s linear',
          zIndex: 100,
          borderBottom: `solid ${size.width > Breakpoint.sm ? '0px transparent' : `1px ${Colors.MEDIUM_GREY}`}`,
        }}
      >
        <MainContainer>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                width: size.width < Breakpoint.sm ? '100%' : 'calc(100% - 200px)',
                marginLeft: size.width < Breakpoint.xs ? 0 : 100,
                marginRight: 0,
                height: 70,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: size.width < Breakpoint.xs ? 'flex-start' : 'center',
                backgroundColor: Colors.WHITE,
              }}
            >
              <div
                style={{
                  width: size.width < Breakpoint.sm ? '100%' : 250,
                  height: 70,
                }}
              >
                <Link
                  to="/"
                  className="Hover"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <h1
                    style={{
                      color: Colors.BLACK,
                      fontFamily: 'Hurme Geometric Sans',
                      fontWeight: 500,
                      width: '100%',
                      textAlign: size.width < Breakpoint.xs ? 'left' : 'center',
                      paddingTop: 15,
                    }}
                  >
                    In2Cord
                  </h1>
                </Link>
              </div>
            </div>
            <div
              style={{
                width: 100,
                height: 70,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <ShoppingCart />
              <div
                style={{
                  marginLeft: 5,
                }}
              >
                <MenuIcon
                  onClick={() => {
                    setSideMenu(!sideMenu);
                  }}
                  active={sideMenu}
                />
              </div>
            </div>
          </div>
        </MainContainer>
      </header>
      {
        size.width > Breakpoint.sm && (
          <Navigation
            topbar={props.topbar}
          />
        )
      }
      <SideMenu
        visible={sideMenu}
        onClick={() => setSideMenu(!sideMenu)}
      />
    </>
  );
}
