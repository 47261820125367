import * as React from 'react';
import {
  useHistory,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import useQuery from '../services/UseQuery';

import Breakpoint from '../styles/Breakpoints';
import UseDimensions from '../services/UseDimensions';

import MainContainer from '../components/Containers/MainContainer';

import Text from '../components/Modules/Text';
import Button from '../components/Modules/Button';
import CartItem from '../components/Sections/CartItem';

import Shopping from '../assets/svg/shopping.svg';
import EmptyShopping from '../assets/svg/empty-shopping.svg';
import useMoneyFormat from '../services/useMoneyFormat';

export default function ShoppingCartPage() {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const size = UseDimensions();
  const formatMoney = useMoneyFormat();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cart = useSelector((state) => state.cart);

  const [status, setStatus] = React.useState(null);

  const totalAmount = cart.lines && cart.lines.reduce(
    (accumulator, value) => accumulator + value.amount_incl_vat,
    0,
  );

  React.useEffect(() => {
    if (query.get('s') === 'success') {
      dispatch({
        type: 'UPDATE_CART',
        payload: {
          token: null,
          lines: [],
          total: null,
        },
      });

      axios.get('/order', {
        headers: {
          'order-token': localStorage.getItem('order-token'),
        },
      })
        .then(({ data }) => {
          setStatus(data.status);
        });
    }
  }, []);

  React.useEffect(() => {
    if (!status) return;

    if (!['canceled', 'failed', 'expired'].includes(status)) {
      localStorage.removeItem('cart-token');
    }
  }, [status]);

  const resultTitle = () => {
    if (query.get('s') === 'request') {
      return 'Product aangevraagd';
    }
    if (status === 'paid' || status === 'open') {
      return 'Bestelling voltooid!';
    }
    return 'Bestelling niet geplaatst';
  };

  const resultText = () => {
    if (query.get('s') === 'request') {
      return 'Je product is succesvol aangevraagd.';
    }
    switch (status) {
      case 'open':
        return 'Je betaling wordt nog verwerkt';
      case 'paid':
        return 'Je bestelling is succesvol geplaatst';
      case 'failed':
        return 'Er is iets mis gegaan bij de betaling, probeer het opnieuw';
      case 'canceled':
        return 'Je hebt de betaling succesvol afgebroken';
      case 'expired':
        return 'Je betaling is verlopen, probeer het opnieuw';
      default:
        return 'Er is iets mis gegaan bij de betaling, probeer het opnieuw';
    }
  };

  return (
    <div
      style={{
        paddingTop: 30,
      }}
    >
      {
        !query.get('s') && (
          <MainContainer>
            <div
              style={{
                marginBottom: 10,
              }}
            >
              <Text
                textAlign="left"
                fontSize="1.5em"
                fontWeight={500}
              >
                Winkelwagen
              </Text>
            </div>
            {
              cart.lines?.length > 0 ? (
                <div
                  style={{
                    width: '100%',
                    minHeight: 350,
                    height: 'auto',
                    paddingBottom: 30,
                  }}
                >
                  {
                    cart.lines.map((e) => (
                      <CartItem
                        key={e.id}
                        id={e.id}
                        image={e.product.images[0]?.path}
                        alt={e.product.images[0]?.description}
                        name={e.product.name}
                        amount={e.amount_incl_vat}
                        amountExclVat={e.amount_excl_vat}
                        quantity={e.quantity}
                        variant={null}
                        product_options={e.product_options}
                      />
                    ))
                  }
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div
                      style={{
                        marginTop: 30,
                        width: size.width < Breakpoint.xs ? '100%' : 250,
                      }}
                    >
                      <Text
                        textAlign={size.width < Breakpoint.xs ? 'center' : 'right'}
                        fontSize="1.25em"
                      >
                        Totaal:
                      </Text>
                      <Text
                        textAlign={size.width < Breakpoint.xs ? 'center' : 'right'}
                        fontWeight={500}
                        fontSize="1.75em"
                      >
                        {formatMoney(totalAmount)}
                      </Text>
                      <div
                        style={{
                          marginTop: 30,
                          width: '100%',
                        }}
                      >
                        <Button
                          text="Afrekenen"
                          onClick={() => {
                            history.push('/checkout');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: '100%',
                    minHeight: 350,
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: 50,
                  }}
                >
                  <Text>Je winkelwagen is leeg</Text>
                  <div
                    style={{
                      marginTop: 30,
                    }}
                  >
                    <Button
                      text="Ga verder met winkelen"
                      onClick={() => {
                        history.push('/');
                      }}
                    />
                  </div>
                </div>
              )
            }
          </MainContainer>
        )
      }
      {
        (query.get('s') === 'success' || query.get('s') === 'request') && (
          <MainContainer>
            <div
              style={{
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    height: 330,
                    width: 330,
                    backgroundImage: `url('${Shopping}')`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              </div>
              <Text
                textAlign="center"
                fontSize="1.5em"
                fontWeight={500}
              >
                { resultTitle() }
              </Text>
              <div
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Text>{ resultText() }</Text>
                <div
                  style={{
                    width: 300,
                    marginTop: 20,
                  }}
                >
                  <Button
                    text="Terug naar homepagina"
                    onClick={() => history.push('/')}
                  />
                </div>
              </div>
            </div>
          </MainContainer>
        )
      }
      {
        query.get('s') === 'cancel' && (
          <MainContainer>
            <div
              style={{
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    height: 330,
                    width: 330,
                    backgroundImage: `url('${EmptyShopping}')`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              </div>
              <Text
                textAlign="center"
                fontSize="1.5em"
                fontWeight={500}
              >
                Er is iets misgegaan...
              </Text>
              <div
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Text>
                  We konden op dit moment je bestelling niet verwerken. Probeer het later nog eens.
                </Text>
                <div
                  style={{
                    width: 300,
                    marginTop: 20,
                  }}
                >
                  <Button
                    text="Terug naar homepagina"
                    onClick={() => history.push('/')}
                  />
                </div>
              </div>
            </div>
          </MainContainer>
        )
      }
    </div>
  );
}
