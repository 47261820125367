import axios from 'axios';

const loadDefaults = (dispatch) => {
  axios.get('/content/items/categories')
    .then((data) => {
      dispatch({
        type: 'SET_COLLECTIONS',
        payload: data.data,
      });
    })
    .catch(() => {});
};

export default loadDefaults;
