import * as React from 'react';
import {
  Instagram,
  Facebook,
} from 'react-feather';

import { useSelector } from 'react-redux';
import UseDimensions from '../../../services/UseDimensions';

import Colors from '../../../styles/Colors';
import Breakpoint from '../../../styles/Breakpoints';

import MainContainer from '../../Containers/MainContainer';
import Container from '../../Containers/Container';

import Text from '../../Modules/Text';
import Link from '../../Modules/Text/Link';

import Logo from '../../../assets/img/logo.jpg';

export default function Footer() {
  const size = UseDimensions();
  const collections = useSelector((state) => state.collections);
  const settings = useSelector((state) => state.settings);

  const about = [
    {
      id: 0,
      title: 'Home',
      href: '/',
    },
    {
      id: 1,
      title: 'Over ons',
      href: '/about',
    },
    {
      id: 2,
      title: 'Kleurenkaart',
      href: '/kleurenkaart',
    },
    {
      id: 3,
      title: 'Algemene Voorwaarden',
      href: '/algemene-voorwaarden',
    },
    {
      id: 4,
      title: 'Privacyverklaring',
      href: '/privacy-policy',
    },
  ];

  return (
    <footer
      style={{
        height: 'auto',
        width: '100%',
        backgroundColor: Colors.WHITE,
        marginTop: 50,
      }}
    >
      <MainContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: size.width < Breakpoint.sm ? 'column' : 'row',
            paddingTop: 30,
            borderTop: `solid 1px ${Colors.MEDIUM_GREY}`,
            justifyContent: size.width < Breakpoint.sm ? 'flex-start' : 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: size.width < Breakpoint.sm ? '100%' : '40%',
              paddingRight: size.width < Breakpoint.sm ? 0 : 20,
              marginBottom: size.width < Breakpoint.sm ? 20 : 0,
              paddingBottom: size.width < Breakpoint.sm ? 30 : 0,
              borderBottom: `solid ${size.width < Breakpoint.sm ? `1px ${Colors.MEDIUM_GREY}` : '0px transparent'}`,
            }}
          >
            <div
              style={{
                marginBottom: 15,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: size.width < Breakpoint.sm ? 'center' : 'flex-start',
              }}
            >
              <div
                style={{
                  height: 150,
                  width: 150,
                  backgroundImage: `url('${Logo}')`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                }}
              />
            </div>
            <Text
              textAlign={size.width < Breakpoint.sm ? 'center' : 'left'}
              fontSize="1.2em"
            >
              {settings.footer_slogan}
            </Text>
          </div>
          <Container
            share={[50, 50]}
            breakpoint="fd"
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  marginBottom: 10,
                }}
              >
                <Text
                  textAlign="left"
                  fontSize="1.3em"
                  fontWeight={500}
                >
                  Over In2Cord:
                </Text>
              </div>
              {
                about.map((e) => (
                  <Link
                    key={e.id}
                    title={e.title}
                    href={e.href}
                    textAlign="left"
                    fontSize="1.2em"
                  />
                ))
              }
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: size.width < Breakpoint.fd ? 20 : 0,
                marginBottom: size.width < Breakpoint.sm ? 20 : 0,
              }}
            >
              <div
                style={{
                  marginBottom: 10,
                }}
              >
                <Text
                  textAlign="left"
                  fontSize="1.3em"
                  fontWeight={500}
                >
                  Producten:
                </Text>
              </div>
              {
                collections.map(({ _id, name, slug }) => (
                  <Link
                    key={_id}
                    title={name}
                    href={`/category/${slug || _id}`}
                    textAlign="left"
                    fontWeight={300}
                    fontSize="1.2em"
                  />
                ))
              }
            </div>
          </Container>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 20,
          }}
        >
          <a
            href="https://www.instagram.com/in2cord/"
            target="_blank"
            rel="noreferrer"
            style={{
              color: Colors.BLACK,
            }}
          >
            <Instagram
              size={30}
            />
          </a>
          <a
            href="https://www.facebook.com/Handmadebyhotdoggies/"
            target="_blank"
            rel="noreferrer"
            style={{
              color: Colors.BLACK,
              marginLeft: 5,
            }}
          >
            <Facebook
              size={30}
            />
          </a>
        </div>
        <div
          style={{
            marginTop: 20,
            borderTop: `solid 1px ${Colors.MEDIUM_GREY}`,
            paddingTop: 20,
            display: 'flex',
            flexDirection: size.width < Breakpoint.sm ? 'column-reverse' : 'row',
            marginBottom: 20,
          }}
        >
          <Text
            textAlign="left"
          >
            {settings.copyright}
          </Text>
          <Text
            textAlign={size.width < Breakpoint.sm ? 'left' : 'right'}
          >
            {`KVK: ${settings.kvk}`}
          </Text>
        </div>
      </MainContainer>
    </footer>
  );
}
