import * as React from 'react';
import {
  X,
} from 'react-feather';

import Colors from '../../styles/Colors';

export default function RemoveButton(props) {
  const [hover, setHover] = React.useState(false);

  return (
    <button
      type="button"
      className="Hover"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={props.onClick}
      style={{
        height: 60,
        width: 60,
        border: `solid 1px ${Colors.MEDIUM_GREY}`,
        backgroundColor: hover ? Colors.LIGHT_GREY : Colors.WHITE,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <X />
    </button>
  );
}
