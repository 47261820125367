import * as React from 'react';

import Colors from '../../styles/Colors';

import Text from './Text';
import Slider from './Slider';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Showcase = (props) => (
  <div
    style={{
      overflow: 'hidden',
      height: 500,
      width: '100%',
      borderRadius: 10,
      position: 'relative',
    }}
  >
    <Slider
      items={1}
    >
      {
          props.data.map((e) => (
            <div
              className={props.loading ? 'Skeleton' : ''}
              key={e.key}
              style={{
                height: 500,
                width: '100%',
                backgroundImage: `url(${e.image})`,
                backgroundPosition: 'center',
                backgroundSize: props.loading ? '400% 400%' : 'cover',
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  left: 30,
                  bottom: 20,
                  right: 30,
                  zIndex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {
                    e.text && (
                      <Text
                        textAlign="left"
                        color={Colors.WHITE}
                        fontSize="2em"
                        fontWeight={700}
                      >
                        {e.text}
                      </Text>
                    )
                  }
                  {
                    e.subText && (
                      <div
                        style={{
                          marginTop: -5,
                        }}
                      >
                        <Text
                          textAlign="left"
                          color={Colors.WHITE}
                          fontSize="1.1em"
                          fontWeight={500}
                        >
                          {e.subText}
                        </Text>
                      </div>
                    )
                  }
                </div>
              </div>
              {
                !props.loading && (
                  <div
                    style={{
                      height: '50%',
                      width: '100%',
                      position: 'absolute',
                      bottom: 0,
                      background: 'linear-gradient(0deg, rgba(0,0,0,0.49763655462184875) 0%, rgba(0,0,0,0) 100%)',
                      zIndex: 0,
                    }}
                  />
                )
              }
            </div>
          ))
        }
    </Slider>
  </div>
);

export default Showcase;
