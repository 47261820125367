import * as React from 'react';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import axios from 'axios';

import { useSelector } from 'react-redux';
import UseDimensions from '../services/UseDimensions';

import Breakpoint from '../styles/Breakpoints';

import MainContainer from '../components/Containers/MainContainer';
import Container from '../components/Containers/Container';

import Text from '../components/Modules/Text';
import Link from '../components/Modules/Text/Link';
import Button from '../components/Modules/Button';
import TextInput from '../components/Modules/TextInput';
import Checkbox from '../components/Modules/Text/Checkbox';
import useQuery from '../services/UseQuery';

export default function CheckoutPage() {
  const { id } = useParams();
  const history = useHistory();
  const size = UseDimensions();
  const query = useQuery();

  const cart = useSelector((state) => state.cart);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [email, setEmail] = React.useState('');

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [street, setStreet] = React.useState('');
  const [additionalStreet, setAdditionalStreet] = React.useState('');
  const [city, setCity] = React.useState('');
  const [postal, setPostal] = React.useState('');

  const [sameAsShipping, setSameAsShipping] = React.useState(true);

  const [billingFirstName, setBillingFirstName] = React.useState('');
  const [billingLastName, setBillingLastName] = React.useState('');
  const [billingCompany, setBillingCompany] = React.useState('');
  const [billingStreet, setBillingStreet] = React.useState('');
  const [billingAdditionalStreet, setBillingAdditionalStreet] = React.useState('');
  const [billingCity, setBillingCity] = React.useState('');
  const [billingPostal, setBillingPostal] = React.useState('');

  const [phone, setPhone] = React.useState('');

  const [errors, setErrors] = React.useState([]);

  const createOrder = () => {
    setErrors([]);
    const errorArray = [];

    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email || !email.match(emailRegex)) errorArray.push({ field: 'email', message: 'Een geldig e-mail adres is verplicht.' });

    if (!firstName) errorArray.push({ field: 'firstName', message: 'Voornaam is verplicht.' });
    if (!lastName) errorArray.push({ field: 'lastName', message: 'Achternaam is verplicht.' });
    if (!street) errorArray.push({ field: 'street', message: 'Straat en huisnummer zijn verplicht.' });
    if (!city) errorArray.push({ field: 'city', message: 'Stad is verplicht.' });
    if (!postal) errorArray.push({ field: 'postal', message: 'Postcode is verplicht.' });

    if (!billingFirstName && !sameAsShipping) errorArray.push({ field: 'billingFirstName', message: 'Voornaam is verplicht.' });
    if (!billingLastName && !sameAsShipping) errorArray.push({ field: 'billingLastName', message: 'Achternaam is verplicht.' });
    if (!billingStreet && !sameAsShipping) errorArray.push({ field: 'billingStreet', message: 'Straat en huisnummer zijn verplicht.' });
    if (!billingCity && !sameAsShipping) errorArray.push({ field: 'billingCity', message: 'Stad is verplicht.' });
    if (!billingPostal && !sameAsShipping) errorArray.push({ field: 'billingPostal', message: 'Postcode is verplicht.' });

    if (errorArray.length > 0) {
      setErrors(errorArray);
      return;
    }

    axios({
      method: 'post',
      url: '/checkout',
      data: {
        email,
        phone,

        firstName,
        lastName,
        companyName: company,
        lineOne: street,
        lineTwo: additionalStreet,
        city,
        postalCode: postal,

        billingFirstName: sameAsShipping ? firstName : billingFirstName,
        billingLastName: sameAsShipping ? lastName : billingLastName,
        billingCompanyName: sameAsShipping ? company : billingCompany,
        billingLineOne: sameAsShipping ? street : billingStreet,
        billingLineTwo: sameAsShipping ? additionalStreet : billingAdditionalStreet,
        billingCity: sameAsShipping ? city : billingCity,
        billingPostalCode: sameAsShipping ? postal : billingPostal,
      },
      headers: {
        'cart-token': cart.token,
      },
    })
      .then((response) => {
        localStorage.setItem('order-token', cart.token);
        window.location.href = response.data.payment_uri;
      });
  };

  const requestProduct = () => {
    setErrors([]);
    const errorArray = [];

    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email || !email.match(emailRegex)) errorArray.push({ field: 'email', message: 'Een geldig e-mail adres is verplicht.' });

    if (!firstName) errorArray.push({ field: 'firstName', message: 'Voornaam is verplicht.' });
    if (!lastName) errorArray.push({ field: 'lastName', message: 'Achternaam is verplicht.' });
    if (!street) errorArray.push({ field: 'street', message: 'Straat en huisnummer zijn verplicht.' });
    if (!city) errorArray.push({ field: 'city', message: 'Stad is verplicht.' });
    if (!postal) errorArray.push({ field: 'postal', message: 'Postcode is verplicht.' });

    if (!billingFirstName && !sameAsShipping) errorArray.push({ field: 'billingFirstName', message: 'Voornaam is verplicht.' });
    if (!billingLastName && !sameAsShipping) errorArray.push({ field: 'billingLastName', message: 'Achternaam is verplicht.' });
    if (!billingStreet && !sameAsShipping) errorArray.push({ field: 'billingStreet', message: 'Straat en huisnummer zijn verplicht.' });
    if (!billingCity && !sameAsShipping) errorArray.push({ field: 'billingCity', message: 'Stad is verplicht.' });
    if (!billingPostal && !sameAsShipping) errorArray.push({ field: 'billingPostal', message: 'Postcode is verplicht.' });

    if (errorArray.length > 0) {
      setErrors(errorArray);
      return;
    }

    try {
      const productOptions = [];

      const json = JSON.parse(query.get('options'));

      Object.keys(json).forEach((key) => {
        productOptions.push({
          key,
          value: json[key],
        });
      });

      axios({
        method: 'post',
        url: '/request',
        data: {
          product: id,

          email,
          phone,

          firstName,
          lastName,
          companyName: company,
          lineOne: street,
          lineTwo: additionalStreet,
          city,
          postalCode: postal,

          billingFirstName: sameAsShipping ? firstName : billingFirstName,
          billingLastName: sameAsShipping ? lastName : billingLastName,
          billingCompanyName: sameAsShipping ? company : billingCompany,
          billingLineOne: sameAsShipping ? street : billingStreet,
          billingLineTwo: sameAsShipping ? additionalStreet : billingAdditionalStreet,
          billingCity: sameAsShipping ? city : billingCity,
          billingPostalCode: sameAsShipping ? postal : billingPostal,

          productOptions,
        },
      })
        .then(() => {
          history.push('/cart?s=request');
        });
    } catch (err) {
      //
    }
  };

  return (
    <div
      style={{
        paddingTop: 30,
      }}
    >
      <MainContainer>
        <div
          style={{
            marginBottom: 10,
          }}
        >
          <Text
            textAlign="left"
            fontSize="1.5em"
            fontWeight={500}
          >
            {id ? 'Product aanvragen' : 'Afrekenen'}
          </Text>
        </div>
        <div
          style={{
            width: '100%',
            minHeight: 350,
            height: 'auto',
            paddingBottom: 50,
          }}
        >
          <div
            style={{
              width: size.width < 1100 ? '100%' : '70%',
            }}
          >
            <div
              style={{
                marginTop: 10,
              }}
            >
              <TextInput
                label="E-mail"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="email"
                value={email}
                required
                autoComplete="email"
                error={errors.find((i) => i.field === 'email')?.message}
              />
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                <Text
                  textAlign="left"
                  fontWeight={500}
                >
                  Shipping Address
                </Text>
              </div>
              <Container
                share={[50, 50]}
                breakpoint="sm"
              >
                <TextInput
                  label="Voornaam"
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  name="firstName"
                  value={firstName}
                  required
                  autoComplete="given-name"
                  error={errors.find((i) => i.field === 'firstName')?.message}
                />
                <div
                  style={{
                    marginTop: size.width < Breakpoint.sm ? 10 : 0,
                  }}
                >
                  <TextInput
                    label="Achternaam"
                    onChange={(e) => setLastName(e.target.value)}
                    type="text"
                    name="lastName"
                    value={lastName}
                    required
                    autoComplete="family-name"
                    error={errors.find((i) => i.field === 'lastName')?.message}
                  />
                </div>
              </Container>
              <div
                style={{
                  marginTop: 13,
                  marginBottom: 13,
                }}
              >
                <TextInput
                  label="Bedrijfsnaam"
                  onChange={(e) => setCompany(e.target.value)}
                  type="text"
                  name="company"
                  value={company}
                  autoComplete="organization"
                />
              </div>
              <Container
                share={[70, 30]}
                breakpoint="xs"
              >
                <TextInput
                  label="Straat en huisnummer"
                  onChange={(e) => setStreet(e.target.value)}
                  type="text"
                  name="street"
                  value={street}
                  required
                  autoComplete="street-address"
                  error={errors.find((i) => i.field === 'street')?.message}
                />
                <div
                  style={{
                    marginTop: size.width < Breakpoint.xs ? 10 : 0,
                  }}
                >
                  <TextInput
                    label="Apt. etc."
                    onChange={(e) => setAdditionalStreet(e.target.value)}
                    type="text"
                    name="additionalStreet"
                    value={additionalStreet}
                  />
                </div>
              </Container>
              <div
                style={{
                  marginTop: 13,
                  marginBottom: 13,
                }}
              >
                <Container
                  share={[40, 60]}
                  breakpoint="xs"
                >
                  <TextInput
                    label="Postcode"
                    onChange={(e) => setPostal(e.target.value)}
                    type="text"
                    name="postal"
                    value={postal}
                    required
                    autoComplete="postal-code"
                    error={errors.find((i) => i.field === 'postal')?.message}
                  />
                  <div
                    style={{
                      marginTop: size.width < Breakpoint.xs ? 10 : 0,
                    }}
                  >
                    <TextInput
                      label="Stad"
                      onChange={(e) => setCity(e.target.value)}
                      type="text"
                      name="city"
                      value={city}
                      required
                      autoComplete="address-level2"
                      error={errors.find((i) => i.field === 'city')?.message}
                    />
                  </div>
                </Container>
              </div>
              <div
                style={{
                  marginTop: 15,
                  marginBottom: 20,
                }}
              >
                <Text
                  textAlign="left"
                  fontSize="1em"
                >
                  Factuuradres:
                </Text>
                <div
                  style={{
                    marginTop: -10,
                  }}
                >
                  <Checkbox
                    title="Hetzelfde als bezorgadres"
                    checked={sameAsShipping}
                    onChange={(value) => setSameAsShipping(value)}
                  />
                </div>
              </div>

              {
                !sameAsShipping && (
                  <>
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 10,
                      }}
                    >
                      <Text
                        textAlign="left"
                        fontWeight={500}
                      >
                        Billing Address
                      </Text>
                    </div>
                    <Container
                      share={[50, 50]}
                      breakpoint="sm"
                    >
                      <TextInput
                        label="Voornaam"
                        onChange={(e) => setBillingFirstName(e.target.value)}
                        type="text"
                        name="firstName"
                        value={billingFirstName}
                        required
                        autoComplete="given-name"
                        error={errors.find((i) => i.field === 'billingFirstName')?.message}
                      />
                      <div
                        style={{
                          marginTop: size.width < Breakpoint.sm ? 10 : 0,
                        }}
                      >
                        <TextInput
                          label="Achternaam"
                          onChange={(e) => setBillingLastName(e.target.value)}
                          type="text"
                          name="lastName"
                          value={billingLastName}
                          required
                          autoComplete="family-name"
                          error={errors.find((i) => i.field === 'billingLastName')?.message}
                        />
                      </div>
                    </Container>
                    <div
                      style={{
                        marginTop: 13,
                        marginBottom: 13,
                      }}
                    >
                      <TextInput
                        label="Bedrijfsnaam"
                        onChange={(e) => setBillingCompany(e.target.value)}
                        type="text"
                        name="company"
                        value={billingCompany}
                        autoComplete="organization"
                      />
                    </div>
                    <Container
                      share={[70, 30]}
                      breakpoint="xs"
                    >
                      <TextInput
                        label="Straat en huisnummer"
                        onChange={(e) => setBillingStreet(e.target.value)}
                        type="text"
                        name="street"
                        value={billingStreet}
                        required
                        autoComplete="street-address"
                        error={errors.find((i) => i.field === 'billingStreet')?.message}
                      />
                      <div
                        style={{
                          marginTop: size.width < Breakpoint.xs ? 10 : 0,
                        }}
                      >
                        <TextInput
                          label="Apt. etc."
                          onChange={(e) => setBillingAdditionalStreet(e.target.value)}
                          type="text"
                          name="additionalStreet"
                          value={billingAdditionalStreet}
                        />
                      </div>
                    </Container>
                    <div
                      style={{
                        marginTop: 13,
                        marginBottom: 13,
                      }}
                    >
                      <Container
                        share={[40, 60]}
                        breakpoint="xs"
                      >
                        <TextInput
                          label="Postcode"
                          onChange={(e) => setBillingPostal(e.target.value)}
                          type="text"
                          name="postal"
                          value={billingPostal}
                          required
                          autoComplete="postal-code"
                          error={errors.find((i) => i.field === 'billingPostal')?.message}
                        />
                        <div
                          style={{
                            marginTop: size.width < Breakpoint.xs ? 10 : 0,
                          }}
                        >
                          <TextInput
                            label="Stad"
                            onChange={(e) => setBillingCity(e.target.value)}
                            type="text"
                            name="city"
                            value={billingCity}
                            required
                            autoComplete="address-level2"
                            error={errors.find((i) => i.field === 'billingCity')?.message}
                          />
                        </div>
                      </Container>
                    </div>
                  </>
                )
              }

              <div
                style={{
                  marginTop: 40,
                }}
              >
                <TextInput
                  label="Telefoon"
                  onChange={(e) => setPhone(e.target.value)}
                  type="text"
                  name="phone"
                  value={phone}
                />
              </div>
              <div
                style={{
                  marginTop: 20,
                }}
              >
                <Container>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginBottom: size.width < Breakpoint.md ? 10 : 0,
                    }}
                  >
                    <Link
                      title="< Terug naar winkelmandje"
                      href="/cart"
                      textAlign="left"
                    />
                  </div>
                  <div>
                    <Button
                      text={id ? 'Aanvragen' : 'Afrekenen'}
                      onClick={id ? requestProduct : createOrder}
                    />
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </MainContainer>
    </div>
  );
}
