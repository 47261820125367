import * as React from 'react';
import {
  useParams,
  useHistory,
  Link,
} from 'react-router-dom';
import axios from 'axios';

import { useSelector } from 'react-redux';
import UseDimensions from '../services/UseDimensions';

import Colors from '../styles/Colors';
import Breakpoint from '../styles/Breakpoints';

import MainContainer from '../components/Containers/MainContainer';
import Container from '../components/Containers/Container';

import Text from '../components/Modules/Text';
import Breadcrumbs from '../components/Modules/Breadcrumbs';
import Button from '../components/Modules/Button';

import Adventure from '../assets/svg/adventure.svg';
import getPrimaryImage from '../services/getPrimaryImage';
import PageLoader from '../components/Modules/PageLoader';
import useMoneyFormat from '../services/useMoneyFormat';

export default function CategoryPage() {
  const { category } = useParams();
  const history = useHistory();
  const size = UseDimensions();
  const moneyFormat = useMoneyFormat();

  const sidebarRef = React.useRef(0);
  const contentRef = React.useRef(0);
  const layoutRef = React.useRef(0);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [breadcrumb, setBreadcrumb] = React.useState([{
    id: 1,
    title: '',
    href: '/',
    active: false,
  }]);
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const collection = useSelector(
    (state) => state.collections.find(
      // eslint-disable-next-line no-underscore-dangle
      (data) => data.slug === category || data._id === category,
    ),
  );

  React.useEffect(() => {
    if (collection) {
      setLoading(true);
      axios({
        method: 'get',
        url: '/content/items/products',
        params: {
          populate: 1,
          filter: {
            $or: [
              { slug: collection.slug },
              // eslint-disable-next-line no-underscore-dangle
              { category: collection._id },
            ],
          },
        },
      })
        .then((response) => {
          setProducts(response.data);
          setLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 404) history.push('/404');
        });
    }
  }, [collection, category]);

  React.useEffect(() => {
    if (collection) {
      setBreadcrumb([
        {
          id: 1,
          title: 'Home',
          href: '/',
          active: false,
        },
        {
          id: 2,
          title: collection.name,
          href: `/category/${category}`,
          active: true,
        },
      ]);
    }
  }, [collection, category]);

  const items = [
    {
      id: 1,
    },
    {
      id: 2,
    },
  ];

  if (loading) {
    return (
      <PageLoader
        visible={loading}
      />
    );
  }

  return (
    <div
      ref={layoutRef}
      style={{
        height: '100%',
        position: 'relative',
      }}
    >
      <div
        style={{
          paddingTop: 30,
        }}
      >
        <MainContainer>
          <Container
            share={[40, 60]}
          >
            <div
              ref={sidebarRef}
              style={{
                width: '100%',
                position: 'relative',
              }}
            >
              <div
                ref={contentRef}
                style={{
                  height: 'auto',
                  position: 'relative',
                  width: '100%',
                }}
              >
                <Breadcrumbs
                  pages={breadcrumb}
                />
                <div
                  style={{
                    marginTop: 5,
                    marginBottom: 20,
                  }}
                >
                  <Text
                    textAlign="left"
                    fontWeight={500}
                    fontSize="3em"
                    textTransform="capitalize"
                  >
                    {breadcrumb[breadcrumb.length - 1].title}
                  </Text>
                  <div
                    style={{
                      paddingRight: 20,
                    }}
                  >
                    <Text
                      textAlign="left"
                    >
                      {collection.description}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                height: '100%',
              }}
            >
              {
                loading
                  ? (
                    items.map((e, i) => (
                      <div
                        /* eslint-disable-next-line no-underscore-dangle */
                        key={e._id}
                        style={{
                          display: 'flex',
                          flexDirection: size.width < Breakpoint.xs ? 'column' : 'row',
                          width: '100%',
                          height: 'auto',
                          borderBottom: items.length - 1 === i ? 'solid 0px transparent' : `solid 1px ${Colors.MEDIUM_GREY}`,
                          paddingBottom: 40,
                          paddingTop: 40,
                        }}
                      >
                        <div
                          className="Skeleton"
                          style={{
                            width: size.width < Breakpoint.xs ? '100%' : 250,
                            minWidth: 250,
                            height: 250,
                            marginRight: 35,
                          }}
                        />
                        <div
                          style={{
                            paddingTop: 20,
                            width: '100%',
                          }}
                        >
                          <div
                            className="Skeleton"
                            style={{
                              height: 20,
                              width: '60%',
                            }}
                          />
                          <div
                            className="Skeleton"
                            style={{
                              marginTop: 5,
                              height: 20,
                              width: '20%',
                            }}
                          />
                        </div>
                      </div>
                    ))
                  )
                  : products.length < 1
                    ? (
                      <div
                        style={{
                          width: '100%',
                          height: 'auto',
                          paddingTop: 50,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            height: 250,
                            width: '100%',
                            backgroundImage: `url(${Adventure})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            marginBottom: 20,
                          }}
                        />
                        <Text
                          fontSize="1.25em"
                          fontWeight={500}
                        >
                          Geen producten gevonden!
                        </Text>
                        <div
                          style={{
                            width: 300,
                            marginTop: 20,
                          }}
                        >
                          <Button
                            text="Terug naar homepagina"
                            onClick={() => history.push('/')}
                          />
                        </div>
                      </div>
                    )
                    : products.map((product, i) => (
                      <Link
                        /* eslint-disable no-underscore-dangle */
                        key={product._id}
                        to={`/product/${product.slug ?? product._id}`}
                        /* eslint-enable no-underscore-dangle */
                        style={{
                          textDecoration: 'none',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: size.width < Breakpoint.xs ? 'column' : 'row',
                            width: '100%',
                            height: 'auto',
                            borderBottom: products.length - 1 === i ? 'solid 0px transparent' : `solid 1px ${Colors.MEDIUM_GREY}`,
                            paddingBottom: 40,
                            paddingTop: 40,
                          }}
                        >
                          <div
                            style={{
                              width: size.width < Breakpoint.xs ? '100%' : 250,
                              minWidth: 250,
                              height: 250,
                              maxHeight: 250,
                              backgroundImage: `url('${getPrimaryImage(product.images).href}')`,
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              marginRight: 35,
                              borderRadius: 7,
                            }}
                          />
                          <div
                            style={{
                              paddingTop: 20,
                              height: 230,
                              maxHeight: 230,
                              overflow: 'hidden',
                              position: 'relative',
                            }}
                          >
                            <Text
                              fontSize="1.25em"
                              fontWeight={500}
                              textAlign="left"
                            >
                              {product.name}
                            </Text>
                            <div
                              style={{
                                marginTop: 5,
                              }}
                            >
                              <Text
                                textAlign="left"
                              >
                                {
                                  moneyFormat(
                                    (product.amount / 100) * (product.tax_group.value + 100),
                                  )
                                }
                                {product.amount.toString().split(/[,.]/).length === 1 && ',-'}
                              </Text>
                            </div>
                            <div
                              style={{
                                marginTop: 10,
                              }}
                            >
                              <Text
                                textAlign="left"
                              >
                                {product.description}
                              </Text>
                            </div>
                            <div
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
                                height: 50,
                                width: '100%',
                              }}
                            />
                          </div>
                        </div>
                      </Link>
                    ))
              }
            </div>
          </Container>
        </MainContainer>
      </div>
    </div>
  );
}
