import * as React from 'react';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import Breakpoint from '../styles/Breakpoints';

import UseDimensions from '../services/UseDimensions';

import MainContainer from '../components/Containers/MainContainer';
import Container from '../components/Containers/Container';

import Text from '../components/Modules/Text';
import Showcase from '../components/Modules/Showcase';
import Dropdown from '../components/Modules/Dropdown';

import ProductDetails from '../components/Sections/ProductDetails';
import CategoryBlock from '../components/Sections/TextBlocks/CategoryBlock';
import PageLoader from '../components/Modules/PageLoader';
import Button from '../components/Modules/Button';
import QuantityInput from '../components/Modules/QuantityInput';

import useMoneyFormat from '../services/useMoneyFormat';

export default function ProductPage() {
  const { product } = useParams();
  const history = useHistory();
  const size = UseDimensions();
  const dispatch = useDispatch();
  const moneyFormat = useMoneyFormat();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [productInfo, setProduct] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [selectedVariant, setSelectedVariant] = React.useState({});
  const [added] = React.useState(false);
  const [quantity, setQuantity] = React.useState(1);
  // const [customText, setCustomText] = React.useState('');

  const cart = useSelector((state) => state.cart);
  const collections = useSelector((state) => state.collections);

  React.useEffect(() => {
    const handleUpdate = ({ data }) => {
      if (data.event === 'cockpit:content.preview' && data.context?.model === 'products') {
        setProduct(data.data);
      }
    };

    window.addEventListener('message', handleUpdate);

    return () => window.removeEventListener('message', handleUpdate);
  }, []);

  React.useEffect(() => {
    axios({
      method: 'get',
      url: '/content/item/products',
      params: {
        populate: 1,
        filter: {
          // eslint-disable-next-line no-underscore-dangle
          $or: [
            { slug: product },
            { _id: product },
          ],
        },
      },
    })
      .then((response) => {
        setProduct(response.data);
        setLoading(false);
      })
      .catch(() => {
        // if (error.response.status === 404) history.push('/404');
      });
  }, []);

  const getCategory = (id) => collections.find(
    // eslint-disable-next-line no-underscore-dangle
    (data) => data.slug === id || data._id === id,
  );

  // eslint-disable-next-line no-unused-vars
  const addToCart = () => {
    axios({
      method: 'post',
      url: '/cart/add',
      data: {
        'cart-token': cart.token ?? undefined,
        /* eslint-disable-next-line no-underscore-dangle */
        product: productInfo._id,
        product_options: selectedVariant,
        quantity,
      },
    })
      .then(({ data }) => {
        if (!cart.token) {
          localStorage.setItem('cart-token', data.token);
          dispatch({
            type: 'SET_CART',
            payload: {
              token: data.token,
              lines: [],
            },
          });
        }

        dispatch({
          type: 'ADD_LINE',
          payload: {
            ...data.line,
            amount_excl_vat: data.line.amount_excl_vat * quantity,
            amount_incl_vat: data.line.amount_incl_vat * quantity,
          },
        });
      });
  };

  const requestProduct = () => {
    /* eslint-disable-next-line no-underscore-dangle */
    history.push(`/checkout/${productInfo._id}?options=${encodeURIComponent(JSON.stringify(selectedVariant))}`);
  };

  React.useEffect(() => {
    if (
      productInfo.product_options
      && productInfo.product_options[Object.keys(productInfo.product_options)[0]] === null
    ) {
      setSelectedVariant(Object.keys(productInfo.product_options)[0]);
    }
  }, [productInfo.product_options]);

  if (loading) {
    return (
      <PageLoader
        visible={loading}
      />
    );
  }

  return (
    <div
      style={{
        paddingTop: size.width <= Breakpoint.sm ? 0 : 30,
      }}
    >
      <MainContainer>
        {
          size.width < Breakpoint.sm && (
            <ProductDetails
              /* eslint-disable-next-line no-underscore-dangle */
              category={getCategory(productInfo.category._id)?.name ?? ''}
              product={product}
              price={productInfo.amount}
              loading={loading}
              btwGroup={productInfo.tax_group}
            />
          )
        }
        <Container
          share={[65, 35]}
          breakpoint="sm"
        >
          <Showcase
            data={productInfo.images ? productInfo.images.filter((i) => i).map((data, index) => ({
              // eslint-disable-next-line no-underscore-dangle
              key: `${data._id}-${index}`,
              image: `${process.env.REACT_APP_CMS_MEDIA_URL}${data.path}`,
            })) : []}
            loading={loading}
          />
          <div>
            {
              size.width >= Breakpoint.sm && (
                <ProductDetails
                  /* eslint-disable-next-line no-underscore-dangle */
                  category={getCategory(productInfo.category._id)?.name ?? ''}
                  product={productInfo.name}
                  price={productInfo.amount}
                  loading={loading}
                  btwGroup={productInfo.tax_group}
                />
              )
            }
            <div
              style={{
                marginBottom: 30,
                marginTop: size.width < Breakpoint.sm ? 15 : 0,
              }}
            >
              {
                loading ? (
                  <div
                    className="Skeleton"
                    style={{
                      width: '100%',
                      height: 110,
                    }}
                  />
                ) : (
                  <Text
                    textAlign="left"
                  >
                    {productInfo.description}
                  </Text>
                )
              }
            </div>

            <div>
              {
                productInfo.product_options && productInfo.product_options.map((option) => (
                  <div
                    key={option.unit}
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <Dropdown
                      label={option.unit}
                      selected={selectedVariant[option.unit]}
                      options={option.options.map((i) => ({
                        key: i.name,
                        name: `${i.name} ${i.price_change_amount !== '0' ? `(${i.price_change_type === 'Bij' ? '+' : '-'} ${moneyFormat((i.price_change_amount / 100) * (productInfo.tax_group.value + 100))})` : ''}`,
                        selected: selectedVariant[option.unit] === i,
                        onClick: () => setSelectedVariant((prevState) => ({
                          ...prevState,
                          [option.unit]: i.name,
                        })),
                      }))}
                    />
                  </div>
                ))
              }
              <div
                style={{
                  width: '100%',
                  marginBottom: 15,
                  marginTop: 25,
                }}
              >
                <QuantityInput
                  amount={quantity}
                  onClickMinus={() => setQuantity((prevState) => prevState - 1)}
                  onClickPlus={() => setQuantity((prevState) => prevState + 1)}
                  width="100%"
                />
              </div>
              <Button
                text={productInfo.type === 'Te koop' ? 'Aan mandje toevoegen' : 'Product aanvragen'}
                onClick={productInfo.type === 'Te koop' ? addToCart : requestProduct}
                disabled={productInfo.product_options.length > Object.keys(selectedVariant).length}
                added={added}
                loading
              />
            </div>

          </div>
        </Container>

        <div
          style={{
            marginTop: 125,
          }}
        >
          <Container
            share={[33, 33, 33]}
            breakpoint="xs"
          >
            {
              collections.slice(0, 3).map(({
                slug, _id, name, image,
              }) => (
                <CategoryBlock
                  key={name}
                  id={_id}
                  slug={slug}
                  title={name}
                  src={`${process.env.REACT_APP_CMS_MEDIA_URL}${image?.path}`}
                />
              ))
            }
          </Container>
        </div>

      </MainContainer>
    </div>
  );
}
