import * as React from 'react';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Text from '../components/Modules/Text';

export default function Page404() {
  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    axios.get('/content/item/settings', {
      params: {
        populate: 1,
      },
    })
      .then((data) => {
        if (!data.data?.maintenance) {
          history.push('/');
        }
      })
      .catch(() => {});
  });

  return (
    <div
      style={{
        width: '100%',
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          paddingBottom: 30,
        }}
      >
        <Text
          fontWeight={500}
          lineHeight={1}
          fontSize="3em"
        >
          In2Cord
        </Text>
      </div>
      <Text
        fontSize="2em"
        fontWeight={500}
        lineHeight={1}
      >
        We zijn nog even bezig!
      </Text>
      <div
        style={{
          marginTop: 10,
        }}
      >
        <Text
          fontSize="1.25em"
          fontWeight={300}
        >
          Onze website is op dit moment in onderhoud
        </Text>
      </div>
    </div>
  );
}
