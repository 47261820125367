/* USER */
export const SET_COLLECTIONS = 'SET_COLLECTIONS';

/* CART */
export const SET_CART = 'SET_CART';
export const ADD_LINE = 'ADD_LINE';
export const DELETE_LINE = 'DELETE_LINE';
export const UPDATE_LINE = 'UPDATE_LINE';
export const UPDATE_CART = 'UPDATE_CART';
export const SET_LINES = 'SET_LINES';
export const RESET_CART = 'RESET_CART';

/* CONTENT */
export const SET_CONTENT = 'SET_CONTENT';

/* SETTINGS */
export const SET_SETTINGS = 'SET_SETTINGS';
