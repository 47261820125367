import * as React from 'react';

import axios from 'axios';
import { useSelector } from 'react-redux';
import Breakpoint from '../styles/Breakpoints';

import UseDimensions from '../services/UseDimensions';

import MainContainer from '../components/Containers/MainContainer';
import Container from '../components/Containers/Container';

import Showcase from '../components/Modules/Showcase';
// import InstagramFeed from '../components/Modules/InstagramFeed';
import PageLoader from '../components/Modules/PageLoader';

import Row from '../components/Sections/Row';
import TextWithImage from '../components/Sections/TextBlocks/TextWithImage';
import CategoryBlock from '../components/Sections/TextBlocks/CategoryBlock';
import SmallHowToBlock from '../components/Sections/TextBlocks/SmallHowToBlock';

export default function IndexPage() {
  const size = UseDimensions();
  const collections = useSelector((state) => state.collections);

  const [content, setContent] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    axios.get('/content/item/home?populate=1')
      .then((data) => {
        setContent(data.data);
        setLoading(false);
      })
      .catch(() => {});
  }, []);

  React.useEffect(() => {
    const handleUpdate = ({ data }) => {
      if (data.event === 'cockpit:content.preview' && data.context?.model === 'home') {
        setContent(data.data);
      }
    };

    window.addEventListener('message', handleUpdate);

    return () => window.removeEventListener('message', handleUpdate);
  }, []);

  if (loading) {
    return (
      <PageLoader
        visible={loading}
      />
    );
  }

  return (
    <>
      <MainContainer>
        <div
          style={{
            marginTop: size.width < Breakpoint.sm ? 0 : 25,
          }}
        >
          <Showcase
            data={
              content.carousel.map((item, index) => ({
                key: index,
                text: item.title,
                subText: item.subtext,
                image: `${process.env.REACT_APP_CMS_MEDIA_URL}${item.image.path}`,
              }))
            }
          />
        </div>
      </MainContainer>

      <div
        style={{
          marginTop: 100,
          marginBottom: 125,
        }}
      >
        <Row
          title={content.product_highlight.title}
          data={content.product_highlight.products}
        />
      </div>

      <MainContainer>

        <div
          style={{
            marginBottom: 125,
            display: 'flex',
            flexDirection: size.width < Breakpoint.md ? 'column' : 'row',
          }}
        >
          <div
            style={{
              width: size.width < Breakpoint.md ? '100%' : '66%',
            }}
          >
            <Container
              share={[50, 50]}
              breakpoint="xs"
            >
              <SmallHowToBlock
                key="con1"
                number={1}
                text={content.convincement_points.one}
              />
              <SmallHowToBlock
                key="con2"
                number={2}
                text={content.convincement_points.two}
              />
            </Container>
          </div>
          <div
            style={{
              width: size.width < Breakpoint.md ? '100%' : '33%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: size.width < Breakpoint.xs ? 'flex-start' : 'center',
            }}
          >
            <div
              style={{
                width: size.width < Breakpoint.md ? '33%' : '100%',
              }}
            >
              <SmallHowToBlock
                key="con3"
                number={3}
                text={content.convincement_points.three}
              />
            </div>
          </div>
        </div>

        <TextWithImage
          title={content.about.title}
          text={content.about.text}
          src={`${process.env.REACT_APP_CMS_MEDIA_URL}${content.about.image.path}`}
          button={[
            {
              id: 1,
              text: content.about.button.text,
              // TODO: If internal link, don't open in blank
              onClick: () => window.open(content.about.button.link, '_blank'),
            },
          ]}
          invert={size.width < Breakpoint.sm}
        />

        <div
          style={{
            marginTop: 125,
          }}
        >
          <Container
            share={[33, 33, 33]}
            breakpoint="xs"
          >
            {
              collections.slice(0, 3).map(({
                _id, name, slug, image,
              }, i) => (
                <CategoryBlock
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={i}
                  id={_id}
                  title={name}
                  slug={slug}
                  src={`${process.env.REACT_APP_CMS_MEDIA_URL}${image.path}`}
                />
              ))
            }
          </Container>
        </div>

        {
          /*
          <div
            style={{
              marginTop: 125,
              marginBottom: 50,
            }}
          >
            <InstagramFeed />
          </div>
          */
        }

      </MainContainer>
    </>
  );
}
