import * as React from 'react';

import Colors from '../../../styles/Colors';

export default function Text(props) {
  return (
    <p
      style={{
        width: '100%',
        textAlign: props.textAlign ?? 'center',
        fontWeight: props.fontWeight ?? 300,
        fontFamily: 'Hurme Geometric Sans',
        fontSize: props.fontSize ?? '1.1em',
        textTransform: props.textTransform ?? 'none',
        color: props.color ?? Colors.BLACK,
        lineHeight: props.lineHeight ?? 1.3,
        whiteSpace: 'pre-line',
      }}
    >
      {props.children}
    </p>
  );
}
