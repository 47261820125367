import * as React from 'react';

import Colors from '../../../styles/Colors';

import Text from '../Text';

export default function Option(props) {
  const [hover, setHover] = React.useState(false);

  return (
    <button
      key={props.key}
      type="button"
      className="Hover"
      onClick={props.onClick}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        width: '100%',
        height: 40,
        backgroundColor: hover ? Colors.LIGHT_GREY : 'transparent',
        border: 'none',
        paddingLeft: 10,
        fontSize: '0.9em',
      }}
    >
      <Text
        textAlign="left"
      >
        {props.data.name}
      </Text>
    </button>
  );
}
