import * as React from 'react';

import Colors from '../../../styles/Colors';

import Arrow from './Arrows';

export default function Slider(props) {
  const height = '100%';

  const wrapperRef = React.useRef(0);
  const itemRef = React.useRef(height);

  const [width, setWidth] = React.useState(0);
  const [active, setActive] = React.useState(0);
  const [items, setItems] = React.useState(1);
  const [itemOffsetWidth, setItemOffsetWidth] = React.useState(height);

  const getDimensions = () => {
    if (wrapperRef.current?.clientWidth) {
      setWidth(wrapperRef?.current?.clientWidth);
    }
  };

  React.useEffect(() => {
    getDimensions();
    setItemOffsetWidth(itemRef.current.clientWidth);
  }, [width, itemRef.current.clientWidth]);

  React.useEffect(() => {
    window.addEventListener('resize', getDimensions);
    return () => window.removeEventListener('resize', getDimensions);
  }, []);

  React.useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: Colors.WHITE,
      }}
    >
      <div
        ref={wrapperRef}
        style={{
          height,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            whiteSpace: 'nowrap',
            flex: 1,
            transition: 'all ease 1s',
            height: '100%',
            width: '100%',
            transform: `
              translateX(
                ${
                  itemOffsetWidth > height
                    ? `calc(-${active} * (101% / ${items}))`
                    : active === (props.children.length - items) && !props.toEnd
                      ? `calc(${itemOffsetWidth}px - (${props.children.length - (items - 1)} * (${itemOffsetWidth}px + 1%) - 1%))`
                      : `calc(-${active} * (${itemOffsetWidth}px + 1%))`
                }
              )
            `,
          }}
        >
          {
            props.children && (
              React.Children.map(props.children, (e) => (
                <div
                  ref={itemRef}
                  key={e}
                  style={{
                    display: 'inline-block',
                    marginRight: '1%',
                    width: items === 1
                      ? '100%'
                      : `calc(${`${100 - (items - 1)}`}% / ${items})`,
                    minWidth: 'auto',
                    maxWidth: items === 1
                      ? '100%'
                      : `calc(${`${100 - (items - 1)}`}% / ${items})`,
                    height: '100%',
                    overflow: 'hidden',
                  }}
                >
                  {
                    React.cloneElement(e, {
                      key: e,
                      style: {
                        maxWidth: '100%',
                        whiteSpace: 'normal',
                        ...e.props.style,
                      },
                    })
                  }
                </div>
              ))
            )
          }
        </div>
      </div>
      <Arrow
        left
        type={props.type}
        onClick={() => active !== 0 && setActive(active - 1)}
        height={height}
        visible={(active !== 0) && !props.disable}
      />
      <Arrow
        right
        type={props.type}
        onClick={
          () => (
            props.toEnd
              ? (active !== props.children.length - 1)
              : (active !== props.children.length - items)
          ) && setActive(active + 1)
        }
        height={height}
        visible={
          props.toEnd
            ? (active !== props.children.length - 1)
            : (props.children.length >= items
                && active !== props.children.length - items
                && !props.disable
            )
        }
      />
    </div>
  );
}
