import * as React from 'react';

import Colors from '../../../styles/Colors';

import Text from '../../Modules/Text';

export default function SmallHowToBlock(props) {
  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
      }}
    >
      <div
        style={{
          paddingLeft: 50,
          position: 'relative',
        }}
      >
        <Text
          fontSize="10em"
          fontWeight={700}
          textAlign="left"
          color={Colors.LIGHT_GREY}
        >
          {props.number}
        </Text>
        <div
          style={{
            position: 'absolute',
            top: '33%',
            width: 200,
            marginLeft: 60,
          }}
        >
          <Text
            fontSize="2em"
            fontWeight={500}
            textAlign="left"
            lineHeight={1}
          >
            {props.text}
          </Text>
        </div>
      </div>
    </div>
  );
}
