import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import axios from 'axios';
import ReactGA from 'react-ga4';
import Cookie from 'cookie-universal';
// import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import store from './services/redux/store';

import ScrollToTop from './services/ScrollToTop';
import './services/Localize';

import Layout from './components/Layout';

import Index from './pages/index';
import About from './pages/about';
import ShoppingCart from './pages/shopping-cart';
import Category from './pages/category';
import Product from './pages/product';
import Colorsheet from './pages/colorsheet';
import Checkout from './pages/checkout';
import Maintenance from './pages/maintenance';
import TermsOfService from './pages/terms-of-service';
import PrivacyPolicy from './pages/privacy-policy';

import Page404 from './pages/404';

import './styles/Global.css';

axios.defaults.baseURL = process.env.REACT_APP_CMS_URL;

const App = () => {
  const cookies = Cookie();

  if (cookies.get('cookies') === true) {
    ReactGA.initialize('G-VM975BXKWV');
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  }

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <Switch>
            <Route exact path="/maintenance" component={Maintenance} />

            <Layout>
              <ScrollToTop>

                <Route exact path="/404" component={Page404} />

                <Route exact path="/" component={Index} />
                <Route exact path="/about" component={About} />
                <Route exact path="/cart" component={ShoppingCart} />
                <Route exact path="/kleurenkaart" component={Colorsheet} />
                <Route exact path="/checkout" component={Checkout} />
                <Route exact path="/checkout/:id" component={Checkout} />
                <Route exact path="/category/:category" component={Category} />
                <Route exact path="/product/:product" component={Product} />
                <Route exact path="/algemene-voorwaarden" component={TermsOfService} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />

              </ScrollToTop>
            </Layout>
          </Switch>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

export default App();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
