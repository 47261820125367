import * as React from 'react';

import Breakpoint from '../../styles/Breakpoints';
import UseDimensions from '../../services/UseDimensions';

export default function Container(props) {
  const size = UseDimensions();

  const getBreakpoint = () => {
    switch (props.breakpoint) {
      case 'fd':
        return Breakpoint.fd;
      case 'xs':
        return Breakpoint.xs;
      case 'sm':
        return Breakpoint.sm;
      case 'md':
        return Breakpoint.md;
      case 'lg':
        return Breakpoint.lg;
      case 'xl':
        return Breakpoint.xl;
      case 'xxl':
        return Breakpoint.xxl;
      case 'max':
        return Breakpoint.max;
      default:
        return Breakpoint.md;
    }
  };

  const breakpoint = getBreakpoint();

  const renderContainer = () => (
    <div
      style={{
        height: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: size.width < breakpoint ? (props.invert ? 'column-reverse' : 'column') : (props.invert ? 'row-reverse' : 'row'),
      }}
    >
      {
        /* eslint-disable react/no-array-index-key */
        props.children.map((i, index) => (
          <div
            key={index}
            style={{
              width: size.width < breakpoint
                ? '100%'
                : props.share
                  ? (`${props.share[index] - (2 - (2 / props.children.length))}%`)
                  : (`${(100 / props.children.length) - (2 - (2 / props.children.length))}%`),
              marginRight: (props.invert ? (props.children.length !== (index + 1)) : props.children.length === (index + 1)) ? null : '2%',
            }}
          >
            {i}
          </div>
        ))
        /* eslint-enable react/no-array-index-key */
      }
    </div>
  );

  return (
    <div style={{
      height: 'auto',
      width: '100%',
      ...props.style,
    }}
    >
      {renderContainer()}
    </div>
  );
}
