import * as React from 'react';

import Breadcrumbs from '../Modules/Breadcrumbs';
import Text from '../Modules/Text';
import useMoneyFormat from '../../services/useMoneyFormat';

export default function ProductDetails(props) {
  const moneyFormat = useMoneyFormat();

  const priceInclVat = (props.price / 100) * (props.btwGroup.value + 100);

  return (
    <>
      <Breadcrumbs
        pages={
        [
          {
            id: 0,
            title: 'Home',
            href: '/',
            active: false,
          },
          {
            id: 1,
            title: props.category,
            href: `/${props.category ? props.category.toString().toLowerCase() : ''}`,
            active: false,
          },
          {
            id: 2,
            title: props.product,
            href: `/${props.category ? props.category.toString().toLowerCase() : ''}/${props.product ? props.product.toString().toLowerCase() : ''}`,
            active: true,
          },
        ]
      }
      />
      <div
        style={{
          marginTop: 10,
          marginBottom: 20,
        }}
      >
        {
          props.loading ? (
            <div
              className="Skeleton"
              style={{
                width: '100%',
                height: 40,
              }}
            />
          ) : (
            <Text
              textAlign="left"
              fontWeight={500}
              fontSize="2em"
              textTransform="capitalize"
            >
              {props.product}
            </Text>
          )
        }
        <div
          style={{
            marginTop: 0,
          }}
        >
          {
            props.loading ? (
              <div
                className="Skeleton"
                style={{
                  width: '40%',
                  height: 30,
                }}
              />
            ) : (
              <Text
                textAlign="left"
                fontWeight={300}
                fontSize="2em"
              >
                {moneyFormat(priceInclVat)}
                {props.price.toString().split(/[,.]/).length === 1 && ',-'}
              </Text>
            )
          }
          <div
            style={{
              marginTop: 0,
            }}
          >
            <Text
              textAlign="left"
              fontWeight={300}
              fontSize="0.8em"
            >
              {
                props.btwGroup.value === 0
                  ? (
                    <>
                      BTW-Vrij
                    </>
                  )
                  : (
                    <>
                      Incl.
                      {' '}
                      {props.btwGroup.value}
                      % BTW
                    </>
                  )
              }
            </Text>
          </div>
        </div>
      </div>
    </>
  );
}
