import * as React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import loadDefaults from '../services/flows/loadDefaults';

import Header from './Sections/Header';
import Footer from './Sections/Footer';
import CookiePopup from './Modules/CookiePopup';

export default function Layout(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [topbar, setTopbar] = React.useState(null);

  React.useEffect(() => {
    axios.get('/content/item/settings', {
      params: {
        populate: 1,
      },
    })
      .then((data) => {
        dispatch({
          type: 'SET_SETTINGS',
          payload: data.data,
        });
        setTopbar(data.data?.topbar);
        if (data.data?.maintenance && window.self === window.top) {
          history.push('/maintenance');
        } else {
          loadDefaults(dispatch);
        }
      })
      .catch(() => loadDefaults(dispatch));
  }, []);

  React.useEffect(() => {
    const cartToken = localStorage.getItem('cart-token');
    if (cartToken) {
      axios.get('/cart', {
        headers: {
          'cart-token': cartToken,
        },
      })
        .then(({ data }) => {
          dispatch({
            type: 'SET_CART',
            payload: data,
          });
        })
        .catch(() => {});
    }
  }, []);

  return (
    <>
      {
        window.location.pathname !== '/maintenance' && (
          <>
            <Header topbar={topbar} />
          </>
        )
      }
      <main
        style={{
          paddingTop: 141,
          width: '100%',
          height: 'auto',
        }}
      >
        { props.children }
      </main>
      {
        window.location.pathname !== '/maintenance' && (
          <>
            <Footer />
            <CookiePopup />
          </>
        )
      }
    </>
  );
}
