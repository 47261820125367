import * as React from 'react';

import Breakpoint from '../../styles/Breakpoints';

import UseDimesions from '../../services/UseDimensions';

export default function MainContainer(props) {
  const size = UseDimesions();

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: size.width < Breakpoint.lg ? '96%' : '80%',
          marginLeft: size.width < Breakpoint.lg ? '2%' : '10%',
          marginRight: size.width < Breakpoint.lg ? '2%' : '10%',
          maxWidth: 1400,
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
