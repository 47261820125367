import * as React from 'react';
import { useSelector } from 'react-redux';

import Colors from '../../../styles/Colors';

import GetScrollHeight from '../../../services/GetScrollHeight';
import GetScrollDirection from '../../../services/GetScrollDirection';

import MainContainer from '../../Containers/MainContainer';

import NavItem from './NavItem';

export default function Navigation(props) {
  const scrollHeight = GetScrollHeight();
  const scrollDirection = GetScrollDirection();
  const collections = useSelector((state) => state.collections);

  return (
    <>
      <nav
        style={{
          height: 35,
          width: '100%',
          backgroundColor: Colors.WHITE,
          borderBottom: `solid 1px ${Colors.MEDIUM_GREY}`,
          zIndex: 100,
          top: (((scrollHeight > 36) && scrollDirection === 'down') || !props.topbar) ? 70 : 105,
          position: 'fixed',
          transition: 'top .2s linear',
        }}
      >
        <MainContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {
              collections.map(({ _id, name, slug }) => (
                <NavItem
                  key={_id}
                  name={name}
                  href={
                    `/category/${slug || _id}`
                  }
                />
              ))
            }
          </div>
        </MainContainer>
      </nav>
    </>
  );
}
