import * as React from 'react';

export default function GetScrollDirection() {
  const [y, setY] = React.useState(window.scrollY);
  const [direction, setDirection] = React.useState('');

  const handleNavigation = React.useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        setDirection('up');
      } else if (y < window.scrollY) {
        setDirection('down');
      }
      setY(window.scrollY);
    }, [y],
  );

  React.useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  return direction;
}
