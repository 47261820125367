import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Text from '../../Modules/Text';

import Colors from '../../../styles/Colors';

export default function SideMenuItem(props) {
  const [hover, setHover] = React.useState(false);

  return (
    <RouterLink
      className="Hover"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      to={props.href}
      onClick={props.onClick}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: -5,
        backgroundColor: 'transparent',
        fontSize: '1em',
        border: 'solid 0px transparent',
        textDecoration: 'none',
      }}
    >
      <div
        style={{
          height: 40,
          width: 40,
          minWidth: 40,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <i
          className={`fa-solid ${props.icon}`}
          style={{
            fontSize: '1.5em',
            color: hover ? Colors.GREY : Colors.MEDIUM_GREY,
          }}
        />
      </div>
      <div
        style={{
          height: 40,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: 5,
        }}
      >
        <Text
          fontSize="1.25em"
          textAlign="left"
        >
          {props.title}
        </Text>
      </div>
    </RouterLink>
  );
}
