import * as React from 'react';
import {
  Minus,
  Plus,
} from 'react-feather';

import Colors from '../../styles/Colors';

import Text from './Text';

export default function QuantityInput(props) {
  const [hoverMinus, setHoverMinus] = React.useState(false);
  const [hoverPlus, setHoverPlus] = React.useState(false);

  const MIN_VALUE = 1;
  const MAX_VALUE = 1000000;

  return (
    <div
      style={{
        width: props.width ?? 180,
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <button
        type="button"
        className="Hover"
        onMouseEnter={() => {
          setHoverMinus(true);
        }}
        onMouseLeave={() => {
          setHoverMinus(false);
        }}
        onClick={props.amount !== MIN_VALUE ? props.onClickMinus : null}
        disabled={props.amount === MIN_VALUE}
        style={{
          height: 60,
          width: 60,
          minWidth: 60,
          border: `solid 1px ${Colors.MEDIUM_GREY}`,
          backgroundColor: hoverMinus ? Colors.LIGHT_GREY : Colors.WHITE,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Minus />
      </button>
      <div
        style={{
          width: props.width ?? 60,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          borderTop: `solid 1px ${Colors.MEDIUM_GREY}`,
          borderBottom: `solid 1px ${Colors.MEDIUM_GREY}`,
        }}
      >
        <Text
          fontWeight={500}
        >
          {props.amount}
        </Text>
      </div>
      <button
        type="button"
        className="Hover"
        onMouseEnter={() => {
          setHoverPlus(true);
        }}
        onMouseLeave={() => {
          setHoverPlus(false);
        }}
        onClick={props.amount !== MAX_VALUE ? props.onClickPlus : null}
        disabled={props.amount === MAX_VALUE}
        style={{
          height: 60,
          width: 60,
          minWidth: 60,
          border: `solid 1px ${Colors.MEDIUM_GREY}`,
          backgroundColor: hoverPlus ? Colors.LIGHT_GREY : Colors.WHITE,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Plus />
      </button>
    </div>
  );
}
