import * as React from 'react';

import Colors from '../../../styles/Colors';

import MainContainer from '../../Containers/MainContainer';

import Text from '../../Modules/Text';

export default function Topbar(props) {
  return (
    <div
      style={{
        height: 70,
        width: '100%',
        backgroundColor: Colors.BLACK,
        top: 0,
        position: 'fixed',
        zIndex: 99,
      }}
    >
      <MainContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: 35,
            paddingTop: 2,
          }}
        >
          <Text
            fontSize="0.8em"
            color={Colors.WHITE}
            fontWeight={500}
          >
            {props.topbar}
          </Text>
        </div>
      </MainContainer>
    </div>
  );
}
