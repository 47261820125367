import { SET_SETTINGS } from '../actionTypes';

const initialState = [];

// eslint-disable-next-line default-param-last
const update = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTINGS:
      return { ...state, ...action.payload };

    default: return state;
  }
};

export default update;
