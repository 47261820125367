import * as React from 'react';
import axios from 'axios';

import UseDimensions from '../services/UseDimensions';

import Breakpoint from '../styles/Breakpoints';

import MainContainer from '../components/Containers/MainContainer';

import TextWithImage from '../components/Sections/TextBlocks/TextWithImage';

import Text from '../components/Modules/Text';
import PageLoader from '../components/Modules/PageLoader';

export default function AboutPage() {
  const size = UseDimensions();

  const [content, setContent] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    axios.get('/content/item/about')
      .then((data) => {
        setContent(data.data);
        setLoading(false);
      })
      .catch(() => {});
  }, []);

  React.useEffect(() => {
    const handleUpdate = ({ data }) => {
      if (data.event === 'cockpit:content.preview' && data.context?.model === 'about') {
        setContent(data.data);
      }
    };

    window.addEventListener('message', handleUpdate);

    return () => window.removeEventListener('message', handleUpdate);
  }, []);

  if (loading) {
    return (
      <PageLoader
        visible={loading}
      />
    );
  }

  return (
    <>
      <MainContainer>
        <div
          style={{
            marginTop: 50,
          }}
        >
          <Text
            textAlign="center"
            fontSize="2em"
            fontWeight={500}
          >
            {content.title}
          </Text>
        </div>
        {
          content.block.map((block, index) => (
            <div
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              style={{
                marginTop: 50,
                marginBottom: 100,
              }}
            >
              <TextWithImage
                invert={size.width < Breakpoint.sm ? true : index % 2 === 1}
                title={block.title}
                text={block.text}
                src={`${process.env.REACT_APP_CMS_MEDIA_URL}${block.image.path}`}
              />
            </div>
          ))
          }
      </MainContainer>
    </>
  );
}
