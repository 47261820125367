import * as React from 'react';
import { Loader } from 'react-feather';
import {
  motion,
  AnimatePresence,
} from 'framer-motion';

import Colors from '../../styles/Colors';

export default function PageLoader(props) {
  return (
    <AnimatePresence>
      {
        props.visible && (
          <motion.div
            style={{
              height: '100vh',
              width: '100vw',
              backgroundColor: Colors.WHITE,
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 10000,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            initial={{
              opacity: 1,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{
              duration: 1.5,
            }}
          >
            <motion.div
              style={{
                width: 50,
                height: 50,
                display: 'flex',
                flexDirection: 'columns',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              initial={{
                rotate: 0,
              }}
              animate={{
                rotate: 360,
              }}
              transition={{
                repeat: Infinity,
                duration: 2,
                ease: 'linear',
              }}
            >
              <Loader
                color={Colors.GREY}
                height={30}
                width={30}
              />
            </motion.div>
          </motion.div>
        )
      }
    </AnimatePresence>
  );
}
