import { combineReducers } from 'redux';
import collections from './reducers/collections';
import cart from './reducers/cart';
import content from './reducers/content';
import settings from './reducers/settings';

const reducers = combineReducers({
  collections,
  cart,
  content,
  settings,
});

export default reducers;
