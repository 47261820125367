import {
  SET_CART,
  ADD_LINE,
  DELETE_LINE,
  UPDATE_LINE,
  UPDATE_CART,
  SET_LINES,
  RESET_CART,
} from '../actionTypes';

const initialState = {
  token: null,
};

// eslint-disable-next-line default-param-last
const update = (state = initialState, action) => {
  switch (action.type) {
    case SET_CART:
      return action.payload;
    case ADD_LINE:
      return { ...state, lines: [...state.lines, action.payload] };
    case DELETE_LINE:
      return {
        ...state,
        lines: state.lines.filter(({ id }) => id !== action.id),
      };
    case UPDATE_LINE:
      return {
        ...state,
        lines: state.lines.map((i) => {
          if (i.id !== action.id) return i;
          return {
            ...i,
            ...action.payload,
          };
        }),
      };
    case UPDATE_CART:
      return {
        ...state,
        ...action.payload,
      };
    case SET_LINES:
      return {
        ...state,
        lines: action.payload,
      };
    case RESET_CART:
      return initialState;

    default: return state;
  }
};

export default update;
