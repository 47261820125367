import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Text from '../components/Modules/Text';
import Button from '../components/Modules/Button';

export default function Page404() {
  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text
        fontSize="8em"
        fontWeight={500}
        lineHeight={1}
      >
        404
      </Text>
      <Text
        fontSize="1.25em"
        fontWeight={300}
      >
        Pagina niet gevonden!
      </Text>
      <div
        style={{
          width: 300,
          marginTop: 20,
        }}
      >
        <Button
          text="Terug naar homepagina"
          onClick={() => history.push('/')}
        />
      </div>
    </div>
  );
}
