import * as React from 'react';
import axios from 'axios';

import MainContainer from '../components/Containers/MainContainer';

import Text from '../components/Modules/Text';
import PageLoader from '../components/Modules/PageLoader';

export default function TermsOfServicePage() {
  const [content, setContent] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    axios.get('/content/item/legal')
      .then((data) => {
        setContent(data.data?.terms_of_service);
        setLoading(false);
      })
      .catch(() => {});
  }, []);

  React.useEffect(() => {
    const handleUpdate = ({ data }) => {
      if (data.event === 'cockpit:content.preview' && data.context?.model === 'legal') {
        setContent(data.data?.terms_of_service);
      }
    };

    window.addEventListener('message', handleUpdate);

    return () => window.removeEventListener('message', handleUpdate);
  }, []);

  if (loading) {
    return (
      <PageLoader
        visible={loading}
      />
    );
  }

  return (
    <>
      <MainContainer>
        <div
          style={{
            marginTop: 50,
          }}
        >
          <Text
            textAlign="center"
            fontSize="2em"
            fontWeight={500}
          >
            {content.title}
          </Text>
        </div>

        <>
          <div
            style={{
              marginTop: 50,
            }}
          >
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: content.content }} />
          </div>
        </>

      </MainContainer>
    </>
  );
}
